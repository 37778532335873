<template>
    <div class="container">

        <!-- HEADER & FILTERS -->
        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Prijslijst</h1>
                    <div class="styled-checkbox">
                        <label>
                            <input type="checkbox" v-model="showHidden">
                            <span>Verborgen artikelen tonen</span>
                        </label>
                    </div>

                    <FabButton
                        @click.native="$router.push('/prices/show')"
                        icon="wrench-screwdriver"
                        v-tooltip="'Selectie & volgorde'"
                    ></FabButton>
                </div>
            </div>
        </div>

        <table class="styled-table">
            <thead class="styled-table-header">
                <tr>
                    <th>
                        Product
                    </th>
                    <th class="text-center">
                        artikelen
                    </th>
                    <th class="text-center">
                        Prijs
                    </th>
                    <th class="text-center">
                        Prijs met dekvel
                    </th>
                </tr>
            </thead>
            <tbody class="styled-table-body">
                <tr class="styled-table-row" v-for="row in filteredBaseArticleCombos.filter(row => row.baseArticles.count() > 0)">
                    <td>
                        {{ row.name }}
                    </td>
                    <td class="text-center">
                        <span v-tooltip="{content: row.articles.pluck('name').join('<br/>'), html: true}">
                            {{ row.articles.count() }}
                        </span>
                    </td>
                    <td class="text-center">
                        <Tooltip style="width: 24px; display: inline-block; margin-right: 16px; color: #999;">
                            <div>
                                <Icon name="clock" />
                            </div>
                            <template #popper>
                                Gisteren: {{ formatNumberAsMoney(row.yesterdayPrice.totalCentsWithoutCoversheet/100) }}<br/>
                                Eergisteren: {{ formatNumberAsMoney(row.beforeYesterdayPrice.totalCentsWithoutCoversheet/100) }}
                            </template>
                        </Tooltip>
                        <Tooltip style="display: inline-block;">
                            <a>{{ formatNumberAsMoney(row.price.totalCentsWithoutCoversheet/100) }}</a>
                            <template #popper>
                                <div v-for="baseArticlePrice of row.price.info.filter(p => ! p.coverSheet)">
                                    {{ baseArticlePrice.name }}: {{ formatNumberAsMoney(baseArticlePrice.centsTotal/100) }}
                                    {{ (
                                        baseArticlePrice.centsTotal === baseArticlePrice.cents
                                        ? ''
                                        : ` (${baseArticlePrice.amount} * ${formatNumberAsMoney(baseArticlePrice.cents/100)})`
                                    ) }}
                                </div>
                            </template>
                        </Tooltip>
                    </td>
                    <td class="text-center">
                        <template v-if="row.coverSheet">
                            {{ formatNumberAsMoney((row.price.totalCents)/100) }}
                            <span style="border-radius: 20px; color: #aaa; font-size: 0.7rem; padding: 3px 5px">
                                +{{ formatNumberAsMoney(row.coverSheet.cents/100) }}
                            </span>
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>

        <div v-if="baseArticleCombos.first(row => row.baseArticles.count() === 0)">
            <h2>Verkochte artikelen zonder basisartikelen</h2>
            <table class="styled-table">
                <tbody class="styled-table-body">
                    <tr class="styled-table-row" v-for="article of baseArticleCombos.first(row => row.baseArticles.count() === 0).articles">
                        {{ article.name }}
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
    import { computed, ref } from 'vue';
    import { useAuthStore } from '@/stores/auth';
    import UseBaseArticleFunctions from './UseBaseArticleFunctions';
    import UsePriceFunctions from './UsePriceFunctions';
    import FabButton from '@/components/FabButton.vue';
    import moment from 'moment';
    import { Tooltip } from 'floating-vue'

    const emit = defineEmits(['breadcrumbs']);

    const authUser = computed(() => useAuthStore().user);
    const { getModels, articlesWithBaseArticles, price } = UseBaseArticleFunctions()
    const { formatNumberAsMoney } = UsePriceFunctions();
    getModels();

    const filteredBaseArticleCombos = computed(() => {
        if (showHidden.value) {
            return baseArticleCombos.value;
        }

        return baseArticleCombos.value.filter(row =>
            row.articles.filter(article => article.useInPricelist).count() > 0
        );
    });

    // Group the article to with and without cover sheet.
    const baseArticleCombos = computed(() => {
        const groupedArticles = articlesWithBaseArticles.value
            .groupBy(article => article.baseArticles.count() > 0 ? article.name.replace('met Dekvel ', '') : '')
            .map(groupedArticleItems => {
                const coverSheetArticle = groupedArticleItems.first(article =>
                    article.baseArticles.contains(a => a.coverSheetId !== null)
                );
                const article = coverSheetArticle ? coverSheetArticle : groupedArticleItems.first();

                const yesterdayPrices = getBaseArticlePricesOfXDaysAgo(article, 1);
                const beforeYesterdayPrice = getBaseArticlePricesOfXDaysAgo(article, 2);

                return {
                    baseArticles: article.baseArticles,
                    articles: groupedArticleItems,
                    name: article.name.replace('met Dekvel ', ''),
                    price: price(article),
                    yesterdayPrice: price(article, yesterdayPrices.all()),
                    beforeYesterdayPrice: price(article, beforeYesterdayPrice.all()),
                    coverSheet: coverSheetArticle ? coverSheetArticle.baseArticles.whereNotNull('coverSheetId').first() : null
                }
            }).values();

        return groupedArticles;
    });

    const getBaseArticlePricesOfXDaysAgo = function(article, subDays) {
        return article.baseArticles.map(baseArticle => {
            const format = 'YYYY-MM-DD HH:mm';
            let baseArticlePrice = baseArticle.baseArticlePrices.first(baseArticlePrice => (
                baseArticlePrice.createdAt.format(format) >= moment().subtract(subDays, 'day').startOf('day').format(format)
                && baseArticlePrice.createdAt.format(format) <= moment().subtract(subDays, 'day').endOf('day').format(format)
            ));

            baseArticlePrice = baseArticlePrice ? baseArticlePrice : baseArticle.latestBeforeTodayBaseArticlePrice;

            return {id: baseArticle.id, cents: baseArticlePrice.cents};
        })
    }

    const showHidden = ref(false);

    emit('breadcrumbs', [
        {label: 'Prijzen', to: '/prices'},
        {label: 'Prijslijst'},
    ]);
</script>
