import WorkSession from './../models/work-session.js';
import moment from 'moment';
import collect from 'collect.js'

// Tijden dat pauzes beginnen in seconden en hoelang de pauzes duren in seconden.
// 1e pauze: start na 2.5 uur * 60 minuten * 60 seconden = 9000 seconden, lengte: 15 minuten * 60 seconden = 900 seconden
// 2e pauze: start na: 5.25 uur * 60 minuten * 60 seconden = 18900 seconden, lengte: 30 minuten * 60 seconden = 1800 seconden
// 3e pauze: start na: 8.5 uur * 60 minuten * 60 seconden = 30600 seconden, lengte: 15 minuten * 60 seconden = 900 seconden
let breaks = [
    {'starts_after': (2.25 * 60 * 60), 'length': (15 * 60)},
    {'starts_after': (4.75 * 60 * 60), 'length': (30 * 60)},
    {'starts_after': (7 * 60 * 60), 'length': (15 * 60)},
];

let harvest_assignment_change_duration = 5 * 60;
let location_change_duration = 5 * 60;

let data = {};

let startLog = [];
const log = [];
let changeReasonForLog = '';

let execute = function(harvestAssignments, users, formulaFactors, date = null) {
    date = date ? date : moment();

    if (formulaFactors.length > 0 && harvestAssignments.count() > 0) {
        setUsers(users, harvestAssignments, date);

        // Sets the starting values of users for the log functionality.
        startLog = users.map(u => ({
            initials: u.initials(),
            name: u.name(),
            started: (
                u.workSession
                    ? u.workSession.starts_at ? u.workSession.starts_at.format('HH:mm') : u.workSession.startsAt.format('HH:mm')
                    : moment().startOf('day').format('HH:mm')
            ),
            busyUntil: u.busyUntil.format('HH:mm'),
            location_id: u.current_location_id,
            harvest_assignment_id: u.current_harvest_assignment_id,
        }))

        // Voor elke oogstopdracht x uitvoeren.
        for (const harvestAssignment of harvestAssignments) {
            const currentLog = {
                harvestAssignment: harvestAssignment,
                data: []
            };

            setLocationAndStartTimes(harvestAssignment, users);

            if (harvestAssignment.users.count() < 1) continue;

            // Zet het geoogste aantal waarmee we gaan rekenen ($calculatedHarvested) gelijk aan het echt geoogste aantal om mee te beginnen.
            harvestAssignment['calculatedHarvested'] = harvestAssignment.harvested * harvestAssignment.content.amount();

            // Pak de gebruikers die gekoppeld zijn aan de oogstopdracht, dit zijn de gebruikers waar mee gerekend moet worden.
            let user_ids = harvestAssignment.users.filter(u => u.pivot.skipped === 0).map(user => user.id);

            let harvestAssignmentUsers = users.filter(user => (
                user_ids.search(user.id) !== false
            ));

            var i = 0;
            if (harvestAssignmentUsers.length < 1) continue;

            // Zolang het aantal geoogste producten kleiner is dan het aantal benodigd, zal er verder gerekend moeten worden.
            while (harvestAssignment.calculatedHarvested < harvestAssignment.bunches()) {
                i++;

                // Empty log for the current time calculation iteration
                changeReasonForLog = [];

                // Pak van de gebruikers die gekoppeld zijn aan de oogstopdracht de gebruikers die klaar zijn om aan de opdracht te werken.
                let usersReadyForLabor = getUsersReadyForLabor(harvestAssignmentUsers, harvestAssignment);

                // Add information to the log functionality.
                let currentLogRow = {
                    harvestedBefore: harvestAssignment.calculatedHarvested,
                    users: usersReadyForLabor.map(u => ({initials: u.initials(), fullName: u.name()})),
                    from: usersReadyForLabor[0].busyUntil.format('HH:mm')
                }

                // Pak de gebruikers die zijn gekoppeld maar niet klaar zijn om te werken, de overgebleven gebruikers dus.
                let usersReadyForLaborIds = usersReadyForLabor.map(user => user.id);
                let usersNotReadyForLabor = harvestAssignmentUsers.filter(user => usersReadyForLaborIds.indexOf(user.id) < 0);

                // Haal de tijd op wanneer de oogstopdracht veranderd in het aantal oogstende gebruikers door pauzes of andere gebruikers die komen helpen oogsten.
                let nextChange = getTimeOfNextChangeInHarvestAssignment(usersNotReadyForLabor, usersReadyForLabor, harvestAssignment.id);

                // Bereken het aantal producten dat kan worden geoogst in de tijd tot $nextChange
                const till = calculateHarvestAssignmentTillChange(harvestAssignment, usersReadyForLabor, nextChange, formulaFactors);

                // Add information to the log functionality, and add the information to the current harvest assignment log.
                currentLogRow['till'] = till.format('HH:mm');
                currentLogRow['changeReason'] = changeReasonForLog;
                currentLogRow['harvestedAfter'] = harvestAssignment.calculatedHarvested;
                currentLog.data.push(currentLogRow);

                if (i >= 1000) {
                    break;
                }
            }

            // Add harvest assignment information log to the whole log array.
            log.push(currentLog)
        }
    }

    return data;
}

function calculateHarvestAssignmentTillChange(harvestAssignment, users, till, formulaFactors)
{
    // Hoeveel moet er nog geoogst worden.
    let harvestNeeded = harvestAssignment.bunches() - harvestAssignment.calculatedHarvested;
    let correctedSpeedFactor = harvestAssignment.correctedSpeed(formulaFactors);

    // The speed of the users combined
    // let speed = _.sumBy(users, 'average_harvest_speed') * correctedSpeedFactor;
    let speed = (users.length * 300) * correctedSpeedFactor;
    let totalHarvestTime = (harvestNeeded / speed);

    // ALS: De starttijd + total oogsttijd groter kleiner is dan de tijd dat het oogstopdracht in snelheid veranderd ($till).
    // DAN: Zet $calculatedHarvested op het totaal benodigde aantal voor de oogstopdracht.
    //      En update de $till tijd naar de tijd wanneer het oogsten klaar is.
    // ANDERS: Zet $calculatedHarvested op het aantal wat de oogsters af kunnen krijgen tussen de $from en $till tijd.
    let from = moment.unix(Math.min(...users.map(user => user.busyUntil.unix())));
    let would_be_completed_at = from.clone().add(totalHarvestTime, 'hour');

    if (till === null || till === false || would_be_completed_at.unix() <= till.unix()) {

        if (would_be_completed_at.isBefore(moment())) {
            would_be_completed_at = moment();
        }
        harvestAssignment.calculatedHarvested = harvestAssignment.bunches();
        harvestAssignment.completedAt = would_be_completed_at.clone().endOf('minute')

        changeReasonForLog.push('Bosopdracht afgerond');
        till = would_be_completed_at.clone();
    } else {
        harvestAssignment.calculatedHarvested += speed * (till.diff(from, 'hour', true));
    }
    // Update de busyUntil van alle oogstende gebruikers naar $till
    for (let user of users) {
        user.busyUntil = till.clone();
    }

    return till;
}

function getTimeOfNextChangeInHarvestAssignment(usersNotReadyForLabor, usersReadyForLabor, id)
{
    // Pak de tijd van de eerst volgende niet oogstende gebruiker (als die er is) die klaar is om te gaan werken aan de oogstopdracht.
    let nextUserReadyOn = null;
    if (usersNotReadyForLabor.length > 0) {
        nextUserReadyOn = Math.min(...usersNotReadyForLabor.map(user => user.busyUntil.unix()));
        nextUserReadyOn = moment.unix(nextUserReadyOn);
    }

    // Pak de eerst volgende pauze van alle oogstende gebruikers.
    let firstUpcomingBreak = null;
    let usersReadyForLaborFirstBreaks = usersReadyForLabor.filter(user => user.breaks.length > 0).map(user => user.breaks[0].starts_at.unix());
    if (usersReadyForLaborFirstBreaks.length > 0) {
        firstUpcomingBreak = Math.min(...usersReadyForLaborFirstBreaks);
        firstUpcomingBreak = moment.unix(firstUpcomingBreak);
    }

    // ALS: de eerste volgende pauze eerder komt dan de eerst vrij komende gebruiker geef dan de pauze informatie terug.
    // ALS: het andersom is geef dan de informatie van de vrij komende gebruiker tijd terug.
    // ANDERS (als beide niet gezet zijn): geef null terug.
    if (
        nextUserReadyOn !== null && (
            firstUpcomingBreak !== null && nextUserReadyOn.isBefore(firstUpcomingBreak)
            || firstUpcomingBreak === null
        )
    ) {
        changeReasonForLog.slice().unshift('Gebruiker komt erbij');
        return nextUserReadyOn;
    } else if (
        firstUpcomingBreak !== null && (
            nextUserReadyOn != null && firstUpcomingBreak.isBefore(nextUserReadyOn)
            || ! nextUserReadyOn
        )
    ) {
        changeReasonForLog.slice().unshift('Pauze');
        return firstUpcomingBreak;
    }

    return false;
}

function getUsersReadyForLabor(users, harvestAssignment)
{
    // Pak alle oogstende gebruikers die klaar zijn om te gaan werken aan deze oogstopdracht
    // aan de hand van iedereen die $busyUntil kleiner of gelijk aan de eerste $busyUntil heeft.
    let busyUntils = users.map(user => user.busyUntil.unix());
    let minBusyUntil = Math.min(...busyUntils);
    let usersReadyForLabor = users.filter(user => user.busyUntil.unix() <= minBusyUntil);

    for (let user of usersReadyForLabor) {
        const harvestAssignmentLocationId = (
            harvestAssignment.location_id !== undefined
            ? harvestAssignment.location_id
            : harvestAssignment.locationId
        );
        // Als de gebruiker hiervoor met een andere oogstopdracht bezig was, voeg dan $harvest_assignment_change_duration
        // (de tijd dat het duurt om van oogstopdracht te veranderen) toe bij de gebruiker zijn $busyUntil.
        if (user.current_harvest_assignment_id != harvestAssignment.id) {
            changeReasonForLog.push(`${user.initials()} oogst change ${harvest_assignment_change_duration/60}m`);
            user.busyUntil.add(harvest_assignment_change_duration, 'seconds');
            user.current_harvest_assignment_id = harvestAssignment.id;
        }

        // Als de gebruiker hiervoor met een andere oogstopdracht bezig was op een ander locatie, voeg dan
        //$location_change_duration (de tijd dat het duurt om van locatie te veranderen) toe bij de gebruiker zijn $busyUntil.
        if (user.current_location_id != harvestAssignmentLocationId) {
            changeReasonForLog.push(`${user.initials()} loc change ${location_change_duration/60}m`);
            user.busyUntil.add(location_change_duration, 'seconds');
            user.current_location_id = harvestAssignmentLocationId;
        }

        // Als de gebruiker met pauze moet gaan, voeg dan de lengte van de pauze toe aan hun $busyUntil en verwijder dan de pauze
        if (user.breaks.length > 0 && user.busyUntil.unix() >= user.breaks[0].starts_at.unix()) {
            changeReasonForLog.push(`${user.initials()} Pauze ${user.breaks[0].length/60}m`);
            user.busyUntil.add(user.breaks[0].length, 'seconds');
            user.breaks.shift();
        }

        user['last_location_id'] = harvestAssignment.locationId;
    }

    // Door de tijden(pauzes, etc) die eventueel zijn toegevoegd sinds het begin van de functie kan de eerste beschikbare medewerker tijd veranderd
    // zijn, als dat het geval is moeten we opnieuw kijken welke gebruikers kunnen gaan oogsten. Dit kunnen andere gebruikers zijn dan
    // de oorspronkelijke gebruikers (begin van de functie) die aan de oogopdracht zouden gaan werken. Bij deze gebruikers kunnen echter
    // ook de tijden weer veranderen door, daarom moet de functie dan opnieuw worden uitgevoerd met de geupdaten gebruiker variable.
    let usersReadyForLaborBusyUntils = usersReadyForLabor.map(user => user.busyUntil.unix());
    let minUsersReadyForLaborBusyUntils = Math.min(...usersReadyForLaborBusyUntils);

    if (minBusyUntil !== minUsersReadyForLaborBusyUntils) {

        return getUsersReadyForLabor(users, harvestAssignment);
    }

    // Als de tijd dat de eerste beschikbare medewerker kan oogsten hetzelfde is gebleven, dan kunnen we de gebruikers die op deze tijd kunnen starten
    // terug geven, deze moeten we wel opnieuw oppakken omdat er gebruikers afgevalen kunnen zijn, echter kunnen er geen nieuw bijgekomen zijn aangezien
    // start tijden alleen verder in de toekomst gezet kunnen worden en niet terug in de tijd.
    return users.filter(user => user.busyUntil.unix() <= minBusyUntil);
}

function setUsers(users, harvestAssignments, date)
{
    for (let user of users) {

        user['last_location_id'] = null;
        user['breaks'] = [];

        if (date.isAfter(new Date(), 'day')) {
            user.busyUntil = moment(date.format('YYYY-MM-DD 00:00:00'));
            continue;
        }

        // Zet $busyUntil op de gebruiker, dit wordt de tijd waarmee we gaan aangeven tot wanneer de gebruiker "virtueel" aan het werk is of weer beschikbaar is,
        // deze wordt constant geüpdate tijdens het bereken van de geschatte oogstijden.
        // Standaard zetten we de tijd op nu.
        user.busyUntil = moment();

        // Als de oogster geen actieve werksessie heeft dan wordt er een fake actieve werksessie gekoppeld met de huidige tijd als starttijd.
        // Deze tijd hebben we nodig om te kunnen bepalen hoe lang een medewerker aan het werk is om bijvoorbeeld te berekenen wanneer een pauze start en eindigd.
        setWorkSessionOnUser(user);

        // Als er in de huidige werksessie al een of meerdere oogstregistraties hebben plaatsgevonden,
        // pakken we de laatste oogstregistratie om te kijken waar de oogster als laatst mee bezig was.
        // Deze hebben we nodig omdat de oogster sinds de laatste registratie en huidige tijd ook werk heeft uitgevoerd.
        // De 'busyUntil' tijd van de oogster zetten we daarom op deze tijd.
        setCurrentlyWorkingOnParameters(user, harvestAssignments);

        // Zet de tijd dat de gebruiker al heeft gewerkt in de huidig actieve werksessie om later te kunnen bepalen wanneer de pauzes ingaan.
        user['time_worked'] = user.busyUntil.diff(user.workSession.starts_at, 'seconds');


        // Zet de tijden dat de gebruiker met pauze zou moeten gaan en de tijden dat hij
        // zijn werk weer zou moeten vervolgen volgens de opgegeven pauze class variable.
        // Als hij op dit moment pauze aan het houden zou moeten zijn, tel dan de tijd dat er nog van de pauze over is op bij zijn $busyUntil
        for (let Break of breaks) {
            let starts_at = user.workSession.starts_at.clone().add(Break.starts_after, 'seconds');
            let ends_at = starts_at.clone().add(Break['length'], 'seconds');

            let length = Break.length;
            if (user.busyUntil.isBefore(starts_at)) {
                user.breaks.push({starts_at: starts_at, ends_at: ends_at, length: length});
            } else if(user.busyUntil.isBefore(ends_at)) {

                user.busyUntil = ends_at.clone();
            }
        }
    }
}
// De werksessie zetten.
// Als de gebruiker een actieve werksessie heeft deze zetten als huidige werksessie,
// Anders: Een fake werksessie met de tijd van nu zetten als actieve werksessie.
function setWorkSessionOnUser(user)
{
    if (user.activeWorkSession !== null) {
        user['workSession'] = _.cloneDeep(user.activeWorkSession);
        user['workSession']['starts_at'] = moment(user['workSession']['startsAt'], 'YYYY-MM-DD HH:mm:ss');
    } else {
        user['workSession'] = new WorkSession();
        user['workSession']['starts_at'] = moment();
    }
}

// Als de gebruiker deze werksessie al werk heeft uigevoerd zet de laatste registratie parameters in de gebruiker om vanaf verder te rekenen.
function setCurrentlyWorkingOnParameters(user, harvestAssignments)
{
    const ha = harvestAssignments.first(ha => ha.id === user.pivot.harvest_assignment_id);

    if (ha && ha.locationId) {
        user.current_location_id = ha.locationId;
        user.last_location_id = ha.locationId;
        user.current_harvest_assignment_id = user.pivot.harvest_assignment_id;

        if (
            user.latestHarvestRegistration !== null
            && user.latestHarvestRegistration.createdAt.unix() >= user.workSession.starts_at.unix()
        ) {
            user.busyUntil = user.latestHarvestRegistration.createdAt.clone();
        } else if (user.activeWorkSession) {
            user.busyUntil = user.activeWorkSession.startsAt.clone()
        } else {
            user.busyUntil = moment().set("hour", 7).set("minute", 0);
        }

    } else {
        if (
            user.latestHarvestRegistration !== null
            && user.latestHarvestRegistration.createdAt.unix() >= user.workSession.starts_at.unix()
        ) {
            user.current_location_id = user.latestHarvestRegistration.location_id;
            user.last_location_id = user.latestHarvestRegistration.location_id;
            user.current_harvest_assignment_id = user.latestHarvestRegistration.harvest_assignment_id;
            user.busyUntil = user.latestHarvestRegistration.createdAt.clone();
        } else if (user.activeWorkSession) {
            user.busyUntil = user.activeWorkSession.startsAt.clone()
        } else {
            user.busyUntil = moment().set("hour", 7).set("minute", 0);
        }
    }
}

function setLocationAndStartTimes(harvestAssignment, users)
{
    /* console.log(
        'HARVESTASSIGNMENT('+harvestAssignment.id+'): ' + harvestAssignment.name() + ' - ' + harvestAssignment.harvested + '/' + harvestAssignment.amount
    );

    for (const user of harvestAssignment.users) {
        const usersUser = users.find(function(u) { return u.id == user.id });

        if (! usersUser) continue;

        console.log('GEBRUIKER: ' + user.name());
        console.log('Aangemeld op: ' + usersUser.activeWorkSession.startsAt.format('DD-MM-YYYY HH:mm'));
        console.log('Start op: ' + user.pivot.starts_at);
        console.log('Bezig tot: ' + usersUser.busyUntil.format('DD-MM-YYYY HH:mm'));

    }

    console.log('______')
    console.log('______')
    console.log('______') */


    harvestAssignment.userLocations = {};
    harvestAssignment.userStartTimes = {};
    for (let user of users) {
        harvestAssignment.userLocations[user.id] = (user.last_location_id != undefined ? user.last_location_id : null);
        harvestAssignment.userStartTimes[user.id] = user.busyUntil.clone();
    }


}

export default execute;
export {execute as calculateHarvestAssignmentTimes, log, startLog}
