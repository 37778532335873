import {AbstractModel} from './abstract-model.js'
import Chest from './chest.js';
import Type from './type.js';
import Size from './size.js';
import Content from './content.js';
import CoverSheet from './cover-sheet.js';
import Pallet from './pallet.js';
import Packaging from './packaging.js';
import Unit from './unit.js';
import Price from './price.js';
import ArticleGroup from './article-group.js';
import collect from 'collect.js'
import moment from 'moment'
import OrderLine from './order-line.js';

export default class Article extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            external_id: null,
            parent_id: null,
            name: null,
            harvest_time: null,
            content_id: null,
            chest_id: null,
            type_id: null,
            size_id: null,
            pallet_id: null,
            cover_sheet_id: null,
            conversion_rate: null,
            manual_conversion_rate: null,
            packaging_id: null,
            chest: null,
            type: null,
            size: null,
            content: null,
            packaging: null,
            pallet: null,
            coverSheet: null,
            image_url: null,
            unit: null,
            prices: collect(),
            parentArticlesTree: collect(),
            childArticlesTree: collect(),
            deleted_at: moment()
        }
    }
    amountForStock() {
        return 0;
    }

    conversion() {
        if (this.manual_conversion_rate || this.manualConversionRate) {
            return (this.manual_conversion_rate ? this.manual_conversion_rate : this.manualConversionRate);
        }

        return this.conversionRate;
    }

    getTransformers() {
        return {
            chest: Chest,
            size: Size,
            type: Type,
            content: Content,
            packaging: Packaging,
            coverSheet: CoverSheet,
            pallet: Pallet,
            unit: Unit
        }
    }

    isSameStock(stock) {
        return (
            "article_id" in stock && stock.article_id == this.id
            || stock.chest_id != null
            && stock.chest_id == this.chest_id
            && stock.content_id == this.content_id
            && stock.size_id == this.size_id
            && stock.type_id == this.type_id
        );
    }

    getCcst()
    {
        return {
            chest_id: (this.chest_id ?? this.chestId),
            content_id: (this.content_id ?? this.contentId),
            size_id: (this.size_id ?? this.sizeId),
            type_id: (this.type_id ?? this.typeId)
        }
    }

    isHarvestable()
    {
        return (
            (this.chest_id !== undefined ? this.chest_id : this.chestId) != null
            && (this.content_id !== undefined ? this.content_id : this.contentId)
            && (this.size_id !== undefined ? this.size_id : this.sizeId)
            && (this.type_id !== undefined ? this.type_id : this.typeId)
        );
    }

    isPallet() {
        return ((this.pallet_id === undefined ? this.palletId : this.pallet_id) !== null);
    }

    isStatie() {
        return (this.name.includes(' statiegeld'));
    }

    hasArticleInBom(articleId) {
        if (
            articleId === this.id
            || Array.isArray(articleId) && articleId.includes(this.id)
        ) {
            return true;
        }
        if (this.childArticlesTree.count() === 0) {
            return false;
        }

        for (const childArticle of this.childArticlesTree) {
            if (childArticle.hasArticleInBom(articleId)) {
                return true;
            }
        }

        return false;
    }

    timesArticleInBom(articleId, amount = 1, total = 0) {
        for (const childArticle of this.childArticlesTree) {
            if (articleId === childArticle.id) {
                total += childArticle.timesArticleInBom(articleId, 1, amount*childArticle.pivot.amount);
            } else if (childArticle.childArticlesTree.count() > 0) {
                return childArticle.timesArticleInBom(articleId, childArticle.pivot.amount*amount);
            }
        }

        return total;
    }

    getAmountOfGrams(gramsUnitId, amount = 1, total = 0)
    {
        for(const childArticle of this.childArticlesTree)
        {
            if (childArticle.unitId === gramsUnitId) {
                total += childArticle.getAmountOfGrams(gramsUnitId, 1, amount*childArticle.pivot.amount);
            } else if (childArticle.childArticlesTree.count() > 0) {
                return childArticle.getAmountOfGrams(gramsUnitId, childArticle.pivot.amount*amount);
            }
        }
        
        return total;
    }

    getMutators()
    {
        return {
            deletedAt: 'datetime'
        }
    }

    relations() {
        return {
            unit: Unit,
            type: Type,
            size: Size,
            articleGroup: ArticleGroup,
            price: Price,
            prices: Price,
            parentArticlesTree: Article,
            childArticlesTree: Article,
            latestOrderLine: OrderLine
        }
    }

    relationPlural()
    {
        return {
            unit: false,
            size: false,
            type: false,
            articleGroup: false,
            price: false,
            prices: true,
            parentArticlesTree: true,
            childArticlesTree: true,
            latestOrderLine: false
        }
    }
}

