import Article from "../../models/article.js"
import { AbstractHttpService } from "./abstract-http-service";

class ArticleService extends AbstractHttpService {

    endpoint() {
        return 'articles';
    }

    createModel(data) {
        return new Article(data);
    }

    updatePrices(payload, parameters = {}) {
        let url = this.makeUrl(parameters, 'update-prices');

        return axios['post'](url, payload);
    }

    changePricelistOrder(payload, parameters = {}) {
        let url = this.makeUrl(parameters, 'change-pricelist-order');

        return axios['post'](url, payload);
    }
}

export default new ArticleService;
