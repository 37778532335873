<template>
    <div v-if="initialized" class="stocks-holder" :class="{ 'is-today': dateIsToday }">
        <div class="stocks-container">
            <div class="sticky-top stock-row">
                <template v-for="product of filteredProducts">
                    <div class="header-block block harvest-product">
                        <div class="product-image">
                            <img :src="product.imageUrl ? product.imageUrl : 'https://s3.eu-central-1.amazonaws.com/jongfresh-dev/products/images/jongfresh_kist.png'" />
                        </div>
                        <div class="product-name">
                            {{ product.name() }}
                        </div>
                    </div>
                    <div v-for="article of product.articles" class="header-block block article">
                        <div class="product-image">
                            <img :src="article.image_url ? article.image_url : 'https://s3.eu-central-1.amazonaws.com/jongfresh-dev/products/images/jongfresh_kist.png'" />
                        </div>
                        <div class="product-name">
                            {{ article.name }}
                        </div>
                    </div>
                </template>
            </div>

            <div class="sticky-left">
                <div v-for="period of periods.filter(p => periodFallsOnDate(p))" class="sticky-left-row" :class="period.classes">
                    {{ period.label }}
                </div>
                <div class="sticky-left-row">&nbsp;</div>
            </div>


            <div class="data">
                <div v-for="(row, index) of contentData" class="data-row" :class="periods.filter(p => periodFallsOnDate(p))[index].classes">
                    <template v-for="column of row">
                        <div
                            @click="
                                column.period.isNow ? $emit('openAddStockModal', {...column.product.getCcst(), amount: Math.floor(column.data.newAmount)}) : null;
                                column.period.type === 'start-stock' ? $emit('openStockHistoryModal', column.product) : null
                            "
                            class="block harvest-product"
                            :class="{negative: column.data.newAmount < 0}"
                        >
                            {{ ! column.data ? '&nbsp;' : Math.floor(column.data.newAmount) }}
                            <span
                                class="stock-amount-added"
                                v-if="column.data && (column.data.diff !== 0 || column.data && 'tooltips' && 'tooltips' in column.data)"
                                v-wbtooltip="column.data && 'tooltips' in column.data ? column.data.tooltips.join('  &#160;  ') : ''"
                            >
                                {{ column.data.diff > 0 ? '+' : '' }}
                                {{ Math.round(column.data.diff) }}
                            </span>
                        </div>

                        <div
                            @click="column.period.isNow ? $emit('openAddStockModal', {article_id: column.product.articles[aIndex].id, amount: Math.floor(articleColumn.newAmount)}) : false"
                            v-for="(articleColumn, aIndex) of column.articles"
                            class="block article"
                            :class="{negative: articleColumn.newAmount < 0}"
                        >
                            {{ ! articleColumn ? '' : Math.floor(articleColumn.newAmount) }}
                            <span class="stock-amount-added" v-if="articleColumn && (articleColumn.diff && articleColumn && 'tooltips' in articleColumn)" v-wbtooltip="articleColumn && 'tooltips' in articleColumn ? articleColumn.tooltips.join('  &#160;  ') : ''">
                                {{ articleColumn.diff > 0 ? '+' : '' }}{{ Math.floor(articleColumn.diff) }}
                            </span>
                        </div>

                    </template>
                </div>

                <div class="data-row">
                    <template v-for="product of filteredProducts">
                        <div class="block harvest-product">
                            <a @click="$emit('openCreateHarvestAssignmentModal', product)" class="add-harvest-assignment">+ Bosopdracht</a>
                        </div>
                        <div class="block article" v-for="articleColumn of product.articles">&nbsp;</div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import collect from 'collect.js';
import HarvestProduct from '../../models/harvest-product';
import Article from '../../models/article';
import moment from 'moment';

const props = defineProps(['date', 'today', 'negativeOnly', 'search']);
const emits = defineEmits(['openAddStockModal', 'openStockHistoryModal', 'openCreateHarvestAssignmentModal', 'loaded'])

/*
|--------------------------------------------------------------------------
| Initialize
|--------------------------------------------------------------------------
| Required data for the stock table that isn't available in stores needs to
| be requested from the api before any thing else is done on the page. These are
| harvest products, start stocks and harvest registrations.
*/
const initialized = ref(false);
onMounted(() => {
    Promise.all([
        getHarvestProducts(),
        getStartStocks(),
        getHarvestRegistrations()
    ]).then(() => {
        content();
        initialized.value = true;
    })
});


/**
 * Set harvest products
 */
import HarvestProductService from '../../services/http/harvest-product-service';
const harvestProducts = ref(collect());
const getHarvestProducts = function () {
    return HarvestProductService.get().then((harvestProductModels) => {
        harvestProducts.value = harvestProductModels;
    });
}

/**
 * Set start stocks
 */
import StartStockService from '../../services/http/start-stock-service';
const startStocks = ref(collect());
const getStartStocks = function () {
    return StartStockService.get({
        include: ['article'],
        filters: { date: props.today.format('YYYY-MM-DD') }
    }).then((startStockModels) => {
        startStocks.value = startStockModels;
    });
}

/**
 * Set harvest registrations
 */
import HarvestRegistrationService from '../../services/http/harvest-registration-service';
const harvestRegistrations = ref(collect());
const getHarvestRegistrations = function () {
    return HarvestRegistrationService.get({filters: {
        date: props.today.format('YYYY-MM-DD'),
        internal_own_registrations: 1
    }}).then((harvestRegistrationsModels) => {
        harvestRegistrations.value = harvestRegistrationsModels;
    });
}

/*
|--------------------------------------------------------------------------
| Stores
|--------------------------------------------------------------------------
| To keep the stock table up-to-date as the application is being used, there
| are alot of stores being used that are being kept updated by websockets.
| Here we "subscribe" to the stores that are needed in the table so we can
| update everything accordingly when data is being updated in a store.
*/

import { useHarvestAssignmentStore } from '../../stores/harvestAssignments';
const harvestAssignmentsStore = useHarvestAssignmentStore();

import { useOrderStore } from '../../stores/orders';
const orderStore = useOrderStore();

import { useCcstStore } from '../../stores/ccst';
const ccstStore = useCcstStore();

import { useStockStore } from '../../stores/stocks';
const stockStore = useStockStore();

import { usePurchaseStore } from '../../stores/purchases';
import StartStock from '../../models/start-stock';

const purchaseStore = usePurchaseStore();

/*
|--------------------------------------------------------------------------
| Refresh data
|--------------------------------------------------------------------------
*/
const refreshTimeout = ref(null);

/* watch(stockStore.stock, (newVal, oldVal) => {
    console.log('testtt')
}); */

watch([
    () => props.search,
    () => props.negativeOnly,
    () => props.date,
    stockStore.stock,
    harvestAssignmentsStore.harvestAssignments,
    harvestAssignmentsStore.allHarvestAssignments,
    orderStore.orders,
    purchaseStore.purchases
], () => {
    if (refreshTimeout.value) {
        clearTimeout(refreshTimeout.value);
    }
    refreshTimeout.value = setTimeout(() => {
        content();
    }, 400);
});

/*
|--------------------------------------------------------------------------
| Date methods
|--------------------------------------------------------------------------
*/
const dateIsToday = computed(() => props.today.format('YYYY-MM-DD') === props.date.format('YYYY-MM-DD'));


/*
|--------------------------------------------------------------------------
| Products
|--------------------------------------------------------------------------
| Products consists of all harvest products and their sale products(sub array)
| that are (planned to) changed at the current date or have products in stock today.
| These are taken from orders, start stock, stock, harvest registrations, purchases
| and harvest assignments. They are displayed at the top bar of the table.
*/
const products = ref([])
const setProducts = function() {
    products.value = [];

    const startStockProducts = startStocks.value
        .filter(ss => Math.floor(ss.amount) !== 0)
        .groupBy(ss => ss.articleId !== null ? 'article' : 'harvest');

    const stockProducts = _.cloneDeep(stockStore.stock)
        .filter(s => Math.floor(s.amount) !== 0)
        .groupBy(s => s.articleId !== null ? 'article' : 'harvest');

    const orderHarvestProducts = orderStore.incompletedOrders.filter(o => (
        o.pickedUpAt.isSameOrAfter(props.today, 'DAY')
        && o.pickedUpAt.isSameOrBefore(props.date, 'DAY')
    )).flatMap(
        (o) => o.orderLines
            .filter(ol => ol.article.isHarvestable())
            .all()
    );

    const completedHarvestAssignments = harvestAssignmentsStore.completedJFHarvestAssignments.filter(ha =>
        ha.lastHarvest !== null
        && ha.lastHarvest.isSame(props.today, 'DAY')
    );

    const harvestProducts = [
        ...orderHarvestProducts,
        ...startStockProducts.get('harvest') ?? [],
        ...stockProducts.get('harvest') ?? [],
        ...harvestRegistrations.value,
        ...completedHarvestAssignments,
        ...purchaseStore.purchases.filter(p => (
            p.createdAt.isSameOrAfter(props.today, 'day')
            && p.createdAt.isSameOrBefore(props.date, 'day')
        )),
        ...harvestAssignmentsStore.allHarvestAssignments.filter(ha => (
            ! completedHarvestAssignments.pluck('id').contains(ha.id)
            && (
                ha.plannedAt.isSameOrAfter(props.today, 'day')
                && ha.plannedAt.isSameOrBefore(props.date, 'day')
            )
        ))
    ];

    const articles = [
        ...startStockProducts.get('article') ?? [],
        ...stockProducts.get('article') ?? []
    ];

    for (let harvestProduct of harvestProducts) {
        products.value = addHarvestProductToProducts(products.value, harvestProduct);
    }

    for (let article of articles) {
        products.value = addArticleToProducts(products.value, article.article, article.amountForStock());
    }

    // From here starts tomorrow products.
    /* products.value.forEach(p => {
        p.startStockAmount = p.totalAmount;
        if (p.articles && p.articles.length > 0) {
            p.articles.forEach(a => {
                a.startStockAmount = a.totalAmount;
            })
        }
    });

    const upcomingHarvestProducts = [
        ...orderStore.incompletedOrders.filter(o => o.pickedUpAt.isBefore(moment().add(1, 'week'), 'DAY')).flatMap(
            (o) => o.orderLines
                .filter(ol => ol.article.isHarvestable())
                .all()
        ),
        ...harvestAssignmentsStore.allHarvestAssignments.filter((ha) =>
            ! ha.done() //&& ha.plannedAt.isAfter(moment(), 'day')
        )
    ];

    for (let harvestProduct of upcomingHarvestProducts) {
        products.value = addHarvestProductToProducts(products.value, harvestProduct);
    } */

    //products = products.filter(p => (p.startStockAmount !== 0 || p.startStockAmount !== 0))

    products.value.sort((a, b) => {
        if (a.type.sequence > b.type.sequence) {
            return 1;
        } else if (a.type.sequence < b.type.sequence) {
            return -1;
        }

        for (const attr of ['contentId', 'sizeId', 'chestId']) {
            const asc = ['contentId', 'chestId'].includes(attr);
            if (a[attr] < b[attr]) {
                return asc ? 1 : -1;
            } else if (a[attr] > b[attr]) {
                return asc ? -1 : 1;
            }
        }

        return (a.content.id >= b.content.id ? -1 : 1);
    });

    return products.value;
};

// Filter the products on the user inputted filters.
const filteredProducts = computed(() => {
    return products.value.filter((product) => {
        return filterProduct(product);
    });
});

const filterProduct = function (product) {
    return (
        // Should be shown by negative only criteria
        (
            ! props.negativeOnly
            || product.totalAmount < 0
        )

        // Should be included because of the current search criteria
        && filterOnSearch(product)

        // Or has articles that should be showed by above filters
        || product.articles && product.articles.filter(a => filterProduct(a)).length > 0
    )
}

const filterOnSearch = function(product) {
    return (
        props.search === ''
        || props.search.toLowerCase().split(' ').every(searchSplit =>
            (product instanceof Article ? product.name : product.name())
            .toLowerCase().split(' ').find(nameSplit => nameSplit.includes(searchSplit))
        )
    );
}

/**
 * Check if the given 'harvestProduct' already exists in the products array,
 * otherwise add it to the products array.
 */
const addHarvestProductToProducts = function (products, harvestProduct) {
    const ccst = harvestProduct.article ? harvestProduct.article.getCcst() : harvestProduct.getCcst();
    let product = products.find(product => sameCcst(product.getCcst(), ccst));

    // Product does not exist in products array yet.
    if (! product) {
        product = harvestProducts.value.first(hp => sameCcst(hp.getCcst(), ccst));

        // Product doen not exist in the harvestProducts so create an "Fake"
        // harvest Product of the given ccst/harvest product.
        if (! product) {
            product = new HarvestProduct(ccst);
            product.chestId = ccst.chest_id;
            product.contentId = ccst.content_id;
            product.sizeId = ccst.size_id;
            product.typeId = ccst.type_id;
        }

        // Set ccst relations.
        product.chest = ccstStore.chests.find(c => c.id === ccst.chest_id);
        product.content = ccstStore.contents.find(c => c.id === ccst.content_id);
        product.size = ccstStore.sizes.find(c => c.id === ccst.size_id);
        product.type = ccstStore.types.find(c => c.id === ccst.type_id);

        // Set sale articles array.
        product.articles = [];
        product.totalAmount = 0;
        product.startStockAmount = 0;

        // Push new "product" to the products array.
        products.push(product);
    }

    product.totalAmount += harvestProduct.amountForStock();

    return products;
}

// Add article to the corresponding harvest product.
// Create the harvest product first if it didnt exist yet.
const addArticleToProducts = function (products, article, amount) {
    products = addHarvestProductToProducts(products, article);

    let product = products.find((product) => product.match(article.getCcst()));

    if (filterOnSearch(article)) {
        let curArticle = product.articles.find((a) => a.id === article.id);

        if (! curArticle) {
            article.totalAmount = 0;
            product.articles.push(article);
            curArticle = product.articles.find((a) => a.id === article.id);
        }

        curArticle.totalAmount += amount;
    }

    return products;
};

/*
|--------------------------------------------------------------------------
| Periods
|--------------------------------------------------------------------------
| Time rows consist of all events that have or are going to change (predictions)
| the stock. Just like products, these taken from orders, stock, harvest registrations,
| purchases and harvest assignments, only are these grouped per time rather than per product.
|
*/
const periods = ref([])
const setPeriods = function() {
    periods.value = [
        ...orderStore.incompletedOrders.filter(o => (
            o.pickedUpAt.isSameOrAfter(props.today, 'DAY')
            && o.pickedUpAt.isSameOrBefore(props.date, 'DAY')
        )).map(o => ({
            label: o.pickedUpAt.format('HH:mm') + ' ' + o.customer.name,
            time: o.pickedUpAt,
            classes: ['order'],
            model: o,
            type: 'order'
        })),
        ...harvestAssignmentsStore.openHarvestAssignments.filter(ha => (
            ha.plannedAt.isSameOrAfter(props.today, 'DAY')
            && ha.plannedAt.isSameOrBefore(props.date, 'DAY')
        )).map(ha => ({
            label: `${ha.completedAt ? ha.completedAt.format('HH:mm') : '??'} ${ha.name()}`,
            time: ha.completedAt ? ha.completedAt : moment().endOf('day'),
            classes: ['harvest-assignment'],
            model: ha,
            type: 'harvest-assignment'
        })),
        ...purchaseStore.purchases.filter(p => (
            p.createdAt.isSameOrAfter(props.today, 'DAY')
            && p.createdAt.isSameOrBefore(props.date, 'DAY')
        )).map(p => ({
            label: 'Inkoop',
            time: p.createdAt,
            classes: ['purchase'],
            model: p,
            type: 'purchase'
        }))
    ];
    if (! dateIsToday.value) {
        periods.value.push(...harvestAssignmentsStore.allHarvestAssignments.filter((ha) => (
            ! ha.done()
            && ha.plannedAt.isAfter(props.today, 'day')
            && ha.plannedAt.isSameOrBefore(props.date, 'day')
        )).map(ha => ({
            label: `${ha.plannedAt.format('HH:mm')} ${ha.name()}`,
            time: ha.plannedAt.clone().add(1, 'hour'),
            classes: ['harvest-assignment'],
            model: ha,
            type: 'harvest-assignment'
        })))
    }
    const from = props.today.clone().startOf('DAY');
    const end = moment();

    while(from < end) {
        let till = from.clone().add(30, 'minutes');
        if (till > end) {till = end.clone();}

        if (
            harvestRegistrations.value.first(hr => hr.createdAt.isBetween(from, till, undefined, '[)'))
            || harvestAssignmentsStore.completedJFHarvestAssignments.first(ha => {
                return ha.lastHarvest !== null && ha.lastHarvest.isBetween(from, till, undefined, '[)')
            })
            || stockStore.stock.first(si => si.createdAt.isBetween(from, till, undefined, '[)'))
            || till.isSameOrAfter(end)
        ) {
            periods.value.push({
                classes: ['stock-changed', till.isSame(end) ? 'current-stock' : null],
                label: `${from.format('HH:mm')} - ${till.isSame(end) ? 'NU' : till.format('HH:mm')}`,
                time: till,
                from: from.clone(),
                isNow: till.isSame(end),
                type: 'time-block'
            });
        }

        from.add(30, 'MINUTE');
    }


    // Add startstock to the array
    periods.value.unshift({label: 'Startvoorraad', type: 'start-stock', time: props.today.clone().startOf('day')});

    if (! dateIsToday.value) {
        periods.value.unshift({label: 'Startvoorraad', type: 'start-stock', time: props.date.clone().startOf('day')});
    }

    // Add end stock to the array
    periods.value.push({label: 'Eindvoorraad', type: 'end-stock', time: props.date.clone().endOf('day'), classes:['stock-row']});


    periods.value.sort((a, b) => a.time.isSameOrAfter(b.time) ? 1 : -1)
};

const periodFallsOnDate = function(period)
{
    return (
        dateIsToday.value || (period.time && period.time.isSame(props.date, 'day'))
    );
}

/*
|--------------------------------------------------------------------------
| Data
|--------------------------------------------------------------------------
*/
let contentData = ref([]);
const content = function() {
    setProducts();
    setPeriods();

    const content = [];
    for (const period of periods.value) {
        const row = [];

        for (const product of filteredProducts.value) {
            let column = {
                product: product,
                period: period,
                data: false,
                articles: product.articles.map(() => false)
            };

            if (period.type === 'order') {
                addOrderToColumn(column, period.model, product);
            } else if (period.type === 'start-stock') {
                addStartStockToColumn(column, product);
            } else if (period.type === 'harvest-assignment') {
                addHarvestAssignmentToColumn(column, period.model, product);
            } else if (period.type === 'purchase') {
                addPurchaseToColumn(column, period.model, product);
            } else if (period.type === 'time-block') {
                if (period.isNow) {
                    column.data = {
                        oldAmount: product.remainingStock,
                        newAmount: product.remainingStock,
                        diff: 0
                    };
                    column.articles = product.articles.map(article => ({
                        oldAmount: article.remainingStock,
                        newAmount: article.remainingStock,
                        diff: 0
                    }));
                }

                addTimeToColumn(column, period, product);
            } else { // Eindvoorraad
                column.data = {oldAmount: product.remainingStock, newAmount: product.remainingStock, diff: 0};
                column.articles = product.articles.map(article => ({oldAmount: article.remainingStock, newAmount: article.remainingStock, diff: 0}));
            }

            row.push(column)
        }

        if (periodFallsOnDate(period)) {
            content.push(row);
        }
    }

    contentData = content;
    emits('loaded')
};

const addOrderToColumn = function(column, order, product)
{
    let orderLines = order.orderLines.filter((orderLine) => sameCcst(product.getCcst(), orderLine.article.getCcst()));

    let combinedArticles = [];

    for (let orderLine of orderLines) {
        let index = combinedArticles.findIndex(article => article.article.id === orderLine.articleId);
        if (index < 0) {
            combinedArticles.push({
                article: orderLine.article,
                amount: 0
            });
            index = combinedArticles.findIndex(article => article.article.id === orderLine.articleId);
        }

        combinedArticles[index].amount += orderLine.remainingAmount();
    }

    // If the article of the order exists in the harvestProduct's articles and has remaining stock.
    // Then check subtract from the article's stock first before subtracting from the harvest product itself.
    let remainingAmount = combinedArticles.reduce((currentAmount, combinedArticle) => {
        return currentAmount + (combinedArticle.amount / combinedArticle.article.conversion())
    }, 0);

    let tooltipAmount = combinedArticles.reduce((currentAmount, combinedArticle) => currentAmount + combinedArticle.amount, 0);

    for (let combinedArticle of combinedArticles) {
        let articleIndex = product.articles.findIndex(article => article.id === combinedArticle.article.id);

        if (articleIndex >= 0 && product.articles[articleIndex].remainingStock > 0) {
            let article = product.articles[articleIndex];

            // If the remaining stock of the article is greater than or equal to the remaining amount of the order line.
            // Then use the remaining amount to substract from the remaining article's stock
            // Else use the article remaining stock to subtract from the remaining article's stock
            let amountToSubtract = article.remainingStock >= combinedArticle.amount
                ? combinedArticle.amount
                : article.remainingStock;

            column.articles.splice(articleIndex, 1, {
                oldAmount: article.remainingStock,
                newAmount: article.remainingStock-amountToSubtract,
                diff: -amountToSubtract,
                tooltips: [-amountToSubtract]
            });

            article.remainingStock -= amountToSubtract;
            combinedArticle.amount -= amountToSubtract;
            tooltipAmount -= amountToSubtract;
            remainingAmount -= amountToSubtract;
        }
    }

    if (remainingAmount > 0 || tooltipAmount != 0) {
        let tooltip = [];
        for (let combinedArticle of combinedArticles) {
            if (combinedArticle.amount > 0) {
                tooltip.push(combinedArticle.amount + ' ' + combinedArticle.article.name);
            }
        }

        column.data = {
            oldAmount: product.remainingStock,
            newAmount: product.remainingStock - remainingAmount,
            diff: -remainingAmount,
            tooltips: tooltip
        };

        product.remainingStock -= remainingAmount;
    }
};


const addStartStockToColumn = function(column, product)
{
    let startStock = column.period.time.isSame(props.today, 'day')
        ? startStocks.value.first(s => sameCcst(s.getCcst(), product.getCcst()))
        : new StartStock({
            ...product.getCcst(),
            id: product.id,
            amount: product.remainingStock,
            date: props.date.clone()
        });

    let amount = (startStock !== undefined ? startStock.amount : 0);

    column.data = {oldAmount: amount, newAmount: amount, diff: 0};
    product.remainingStock = amount;
    // Harvest product articles
    column.articles = [];
    for (let article of product.articles) {
        let startStock = column.period.time.isSame(props.today, 'day')
        ? startStocks.value.first(s => s.articleId === article.id)
        : new StartStock({
            ...article.getCcst(),
            id: article.id,
            amount: article.remainingStock,
            date: props.date.clone()
        });


        let amount = (startStock !== undefined ? startStock.amount : 0);

        column.articles.push({oldAmount: amount, newAmount: amount, diff: 0});
        article.remainingStock = amount;
    }
};

const addPurchaseToColumn = function(column, purchase, product)
{
    if (sameCcst(purchase.getCcst(), product.getCcst())) {
        let amount = purchase.amount;

        column.data = {
            oldAmount: product.remainingStock,
            newAmount: product.remainingStock + amount,
            diff: amount,
            tooltips: ['+' + amount + ' Inkoop']
        };
        product.remainingStock += amount;
    }

};


const addHarvestAssignmentToColumn = function(column, harvestAssignment, product)
{
    if (! sameCcst(harvestAssignment.getCcst(), product.getCcst())) return;

    let minus = _.sumBy(harvestAssignment.harvestRegistrations.filter(hr => (! hr.date.isSame(moment(), 'day'))), 'harvested');

    let amount = harvestAssignment.amount;
    amount -= minus;

    column.data = {
        oldAmount: product.remainingStock,
        newAmount: product.remainingStock + amount,
        diff: amount,
        tooltips: ['+' + amount + ' Bosopdracht']
    };
    product.remainingStock += amount;
}

const addTimeToColumn = function(column, period, product)
{
    let tooltips = [];
    let curAmount = 0;
    let changed = false;

    // Add harvest product of harvest assignments
    const harvestAssignments = harvestAssignmentsStore.completedJFHarvestAssignments.filter(ha => {
            return sameCcst(ha.getCcst(), product.getCcst())
            && ha.lastHarvest !== null
            && ha.lastHarvest.isBetween(period.from, period.time, undefined, '[)')
        }
    );

    let amount = 0;

    for (let harvestAssignment of harvestAssignments) {
        // Sum harvested of all harvestAssignment's harvestRegistrations.
        curAmount = harvestAssignment.harvestRegistrations.all().reduce((partialSum, hr) => partialSum + (hr.date.isSame(moment(), 'day') ? hr.harvested : 0), 0);
        amount += curAmount;
        tooltips.push(amount + ' Bosopdracht');
        changed = true;
    }

    // Add harvest registrations
    const filteredHarvestRegistrations = harvestRegistrations.value.filter(harvestRegistration => (
        harvestRegistration.createdAt.isBetween(period.from, period.time)
        && sameCcst(harvestRegistration.getCcst(), product.getCcst())
    ));

    curAmount = filteredHarvestRegistrations.reduce((curAmount, hr) => curAmount + hr.harvested, 0)
    if (curAmount !== 0) {
        amount += curAmount;
        tooltips.push(`${curAmount} Eigen registratie`);
        changed = true;
    }

    // Add stock
    let stock = stockStore.stock.filter(stockItem => {
        return (
            stockItem.createdAt.isBetween(period.from, period.time, undefined, '[)')
            && sameCcst(stockItem.getCcst(), product.getCcst())
        );
    });

    let stocks = stock.filter(stockItem => stockItem.createdAt.isSame(moment(), 'day'));
    amount += stocks.reduce((curAmount , s) => curAmount + s.amount, 0);

    if (amount !== 0 || changed) {
        // Replace harvestProduct column
        tooltips = tooltips.concat(stocks.filter(stock => stock.order !== null).map(stock => stock.amount + ' ' + (stock.order.customer !== null ? stock.order.customer.name : 'Onbekend')).all());
        tooltips = tooltips.concat(stocks.filter(stock => stock.dayHarvestKilo !== null).map(stock => (stock.amount >= 0 ? '+' : '') + stock.amount + ' ' + ' Dagoogst').all());
        tooltips = tooltips.concat(stocks.filter(stock => stock.order === null && stock.dayHarvestKilo === null).map(stock => (stock.amount >= 0 ? '+' : '') + stock.amount + ' ' + ' Handmatig toegevoegd').all());

        column.data = {
            oldAmount: product.remainingStock,
            newAmount: product.remainingStock + amount,
            diff: amount,
            tooltips
        }

        product.remainingStock = product.remainingStock+amount;
    }

    // Add article of stock
    for (let index in product.articles) {
        let stocks = stockStore.stock.filter(stockItem => (
            stockItem.createdAt.isBetween(period.from, period.time)
            && stockItem.articleId === product.articles[index].id
        ));

        amount = parseInt(stocks.reduce((curAmount, stock) => curAmount + stock.amount, 0));

        if (stocks.count() > 0) {
            column.articles[index] = {
                oldAmount: parseInt(product.articles[index].remainingStock),
                newAmount: parseInt(product.articles[index].remainingStock)+amount,
                diff: amount,
                tooltips: [
                    ...stocks.filter(stock => stock.order !== null).map(stock => stock.amount + ' ' + (stock.order.customer !== null ? stock.order.customer.name : 'Order/Klant Onbekend')).all(),
                    ...stocks.filter(stock => stock.order === null).map(stock => stock.amount + ' handmatig toegevoegd').all()
                ]
            };

            product.articles[index].remainingStock = parseInt(product.articles[index].remainingStock)+amount;
        }
    }
}




/*
|--------------------------------------------------------------------------
| Ccst
|--------------------------------------------------------------------------
*/

// check if the two given csst have the same content.
const sameCcst = function (ccst, ccst2) {
    return (
        ccst.chest_id === ccst2.chest_id
        && ccst.content_id === ccst2.content_id
        && ccst.size_id === ccst2.size_id
        && ccst.type_id === ccst2.type_id
    );
}

</script>
