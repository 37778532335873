<template>
    <div class="container" >
        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1 v-if="user.value">{{ user.value.name() }}</h1>
                    <div class="horizontal-fab-button-holder">
                        <fab-button @click.native="create = true" icon="plus"></fab-button>
                    </div>
                </div>

                <div class="page-header-settings">
                    <div class="row">
                        <div class="col col-md-6 col-lg-4">
                            <div class="form-control datepicker-styling">
                                <span class="styled-label">
                                    Datum
                                </span>
                                <DatePicker v-model="date" @input="changeDate" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="tabs-holder">
                    <ul class="tabs">
                        <li :class="{active: harvestAssignments}" @click="harvestAssignments = true">
                            <a>Bosopdrachten</a>
                        </li>
                        <li :class="{active: ! harvestAssignments}" @click="harvestAssignments = false">
                            <a>Eigen registraties</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div v-if="filteredHarvestRegistrations.count() > 0" class="styled-list">
            <ListRow
                @click.native="setEdit(harvestRegistration)"
                v-for="(harvestRegistration, index) of filteredHarvestRegistrations"
                :harvestAssignments="harvestAssignments"
                :harvestRegistration="harvestRegistration"
                :key="index"
            ></ListRow>
        </div>
        <div v-else class="row">
            <div class="col">
                <div class="no-data-placeholder">
                    Geen oogstregistraties gevonden
                </div>
            </div>
        </div>

        <CreateProduct
            v-if="create"
            @close="create = false"
            @submitted="addHarvestRegistration"
            header="Nieuwe eigen registratie"
            amountAs="harvested"
            :amount="create.harvested"
            :locations="locations"
            :date="{date: dateObj.format('YYYY-MM-DD')}"
            step="2"
        />

        <CreateProduct
            v-if="edit"
            @close="edit = false"
            :product="edit"
            @submitted="editHarvestRegistration"
            @delete="confirm = edit; edit = false"
            header="Eigen registratie wijzigen"
            amountAs="harvested"
            :amount="edit.harvested"
            :locations="locations"
            :date="{date: dateObj.format('YYYY-MM-DD')}"
            :includeDelete="true"
        />

        <Modal v-if="confirm" @save="deleteHarvestRegistration(confirm.id)" @close="confirm = false">
            <template v-slot:header>Bevestigen</template>

            Weet je zeker dat je deze dagoogst wilt verwijderen?

            <template v-slot:save>Ja</template>
            <template v-slot:close>Nee</template>

        </Modal>

    </div>
</template>

<script setup>

    import moment from 'moment';
    import ListRow from './ListRow.vue'
    import Location from '../../../models/location.js';
    import User from '../../../models/user.js';
    import CreateProduct from '../../../components/CreateProduct/CreateProduct.vue';
    import axios from 'axios';
    import { useToast } from 'vue-toast-notification';
    import { useRoute } from 'vue-router';
    import { ref, computed } from 'vue'
    import userService from '@/services/http/user-service';
    import harvestRegistrationService from '@/services/http/harvest-registration-service';
    import collect from 'collect.js'

    const props = defineProps(['id'])
    const emit = defineEmits(['breadcrumbs'])

    const route = useRoute()

    const filteredHarvestRegistrations = computed(() => {
        console.log(harvestRegistrations.value)

        return harvestRegistrations.value.filter((hr) => {
            return harvestAssignments.value && hr.harvestAssignmentId != null
            || ! harvestAssignments.value && hr.harvestAssignmentId == null
        })
    });
    const dateObj = computed(() => moment(date.value, 'YYYY-MM-DD'));

    const date = ref(route.query.date_string ? route.query.date_string : moment().format('YYYY-MM-DD'));

    const user = ref(new User());
    const harvestRegistrations = ref(collect());
    const locations = ref([]);

    const include = ['size', 'content', 'type', 'chest', 'location', 'harvestAssignment', 'lastUpdatedByUser'];
    const create = ref(false);
    const edit = ref(false);
    const del = ref(false);
    const harvestAssignments = ref(true);

    const confirm = ref(false);

    const setEdit = function(hr) {
        edit.value = hr;
    }

    const changeDate = function(){
        getData();
        breadcrumbs();
    }

    const editHarvestRegistration = function(form) {
        harvestRegistrationService.edit(edit.value.id, form.data()).then(() => {

            document.querySelector('body').classList = '';
            getData();

            useToast().success('Registratie gewijzigd');
        })
    }

    const addHarvestRegistration = async function(form) {
        await form.post('harvest-registrations?include=' + include, {user_id: props.id});

        document.querySelector('body').classList = '';
        getData();
        useToast().success('Registratie opgeslagen');
    }

    const deleteHarvestRegistration = async function(id) {
        await axios.delete('harvest-registrations/' + id);

        document.querySelector('body').classList = '';
        getData();
        confirm.values = false;
        useToast().success('Registratie verwijderd');
    }

    const breadcrumbs = function() {
        emit('breadcrumbs', [
            {label: 'Registraties', to: '/registrations/select-date'},
            {label: dateObj.value.format('DD-MM-YYYY'), to: '/registrations?date_string=' + dateObj.value.format('YYYY-MM-DD')},
            {label: user.value.name()},
        ])
    }

    const getData = function() {
        harvestRegistrationService.get({
            filters: {user_id_is: props.id, date_is: dateObj.value.format('YYYY-MM-DD')},
            include: include
        }).then(harvestRegistrationModels => {
            harvestRegistrations.value = harvestRegistrationModels
        })
    }

    userService.getById(props.id).then(userModel => {
        user.value = userModel
        breadcrumbs();
    })


    axios.get('/locations?with-count=latestHarvestAssignments').then(response => {
        for (let locationData of response.data.data) {
            let location = new Location();
            location.setAttributesFromResponse(locationData);
            locations.value.push(location);
        }
    });

    getData();
</script>
