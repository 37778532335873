<template>
    <modal class="edit-article" @save="save()" @close="$emit('close')">
        <template v-slot:header>{{ isArticle ? article.name : article.name() }}</template>
        <div>
            <form @submit.prevent enctype="multipart/form-data">
                <SingleSelect
                    v-model="form.image_url"
                    placeholder="Kies één afbeelding"
                    :options="productImages.map(image => ({label: image.name.replace(/_/gi, ' ').split('.').slice(0, -1).join('.'), value: image.url}))"
                ></SingleSelect>
            </form>

            <img
                class="selected-article-photo"
                :src="form.image_url ? form.image_url :
                'https://s3.eu-central-1.amazonaws.com/jongfresh-dev/products/images/jongfresh_kist.png'"
            />
        </div>
    </modal>
</template>

<script setup>
    import Article from './../../../../models/article.js';
    import Form from './../../../../helpers/form.js';
    import { useToast } from 'vue-toast-notification';
    import SingleSelect from '@/components/Form/SingleSelect.vue';
    import {ref } from 'vue';

    const props = defineProps(['article', 'productImages']);
    const emit = defineEmits(['updated', 'close']);
    const isArticle = (props.article instanceof Article);

    const form = ref(new Form({
        chest_id: props.article.chest_id,
        content_id: props.article.content_id,
        size_id: props.article.size_id,
        type_id: props.article.type_id,
        image_url: props.article.image_url ? props.article.image_url : "",
    }))

    const save = function() {
        emit('updated', form.value.image_url);
        document.querySelector('body').classList = '';
        emit('close');

        form.value.put(isArticle ? '/articles/' + props.article.id : '/harvest-products')
        .then(function(response){
            useToast().success((isArticle ? 'Artikel' : 'Oogstproduct') + ' gewijzigd');
        }.bind(this));
    }



</script>
