import BaseArticle from "../../models/base-article";
import { AbstractHttpService } from "./abstract-http-service";

class BaseArticleService extends AbstractHttpService {

    endpoint() {
        return 'base-articles';
    }

    createModel(data) {
        return new BaseArticle(data);
    }

    updatePrices(payload, parameters = {}) {
        let url = this.makeUrl(parameters, 'update-prices');

        return axios['post'](url, payload);
    }
}

export default new BaseArticleService;
