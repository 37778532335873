import Content from './../models/content.js';
import Chest from './../models/chest.js';
import Size from './../models/size.js';
import Type from './../models/type.js';
import { ref } from 'vue';
import { defineStore } from 'pinia'

export const useCcstStore = defineStore('ccst', () => {

    const chests = ref([]);
    const contents = ref([]);
    const sizes = ref([]);
    const types = ref([]);
    const ready = ref(false);
    const promise = ref(false);

    const setChests = (chests) => {chests.value = chests;};
    const setContents = (contents) => {contents.value = contents;};
    const setSizes = (sizes) => {sizes.value = sizes;};
    const setTypes = (types) => {types.value = types;};

    const setCcstReady = (state) => {
        ready.value = (
            chests.value.length > 0
            && contents.value.length > 0
            && sizes.value.length > 0
            && types.value.length > 0
        );
    }

    const getChests = () => getObjects('chests', Chest);
    const getContents = () => getObjects('contents', Content);
    const getTypes = () => getObjects('types', Type);
    const getSizes = () => getObjects('sizes', Size);

    const getObjects = function(endpoint, model) {
        return axios.get(`/${endpoint}?with-count=latestHarvestAssignments`)
            .then(response => {
                let models = [];

                for (let modelData of response.data.data) {
                    let obj = new model();
                    obj.setAttributesFromResponse(modelData);
                    models.push(obj);
                }

                if (endpoint === 'chests') {chests.value = models}
                else if (endpoint === 'contents') {contents.value = models}
                else if (endpoint === 'sizes') {sizes.value = models}
                else if (endpoint === 'types') {types.value = models}
            });
    }

    const getCcst = function() {
        promise.value = Promise.all([
            getChests(),
            getContents(),
            getTypes(),
            getSizes()
        ]);

        return promise.value;
    }

    return {
        // States
        chests, contents, sizes, types, ready, promise,

        // Actions
        setCcstReady,
        setChests, setContents, setSizes, setTypes,
        getChests, getContents, getSizes, getTypes,
        getCcst, getObjects
    }
});
