<template>
    <div class="container">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Artikelia koekoek</h1>
                </div>
            </div>
        </div>


        <table class="styled-table">
            <thead class="styled-table-header">
                <tr>
                    <th>Volgorde</th>
                    <th class="text-left">Artikel</th>
                    <th>Tonen in prijslijst</th>
                    <th>Laatst toegevoegd op</th>
                </tr>
            </thead>
            <tbody class="styled-table-body">
                <tr v-for="(article, index) in articles" :key="'a' + index">
                    <td>
                        <select style="padding: 4px;" @change="(evt) => changeOrder(article, evt.target.value)">
                            <option :selected="articleOption.id === article.id" v-for="(articleOption, articleIndex) in articles" :key="'b' + articleIndex" :value="articleOption.id">
                                {{ articleIndex+1 }} - {{ articleOption.name }}
                            </option>
                        </select>
                    </td>
                    <td class="text-left">
                        {{ article.name }}
                    </td>
                    <td>
                        <div class="styled-checkbox">
                            <label>
                                <input @change="articleChanged(article)" v-model="article.useInPricelist" :value="true" type="checkbox" >
                                <span>Zichtbaar</span>
                            </label>
                        </div>
                    </td>
                    <td>
                        {{ article.latestOrderLine.order.pickedUpAt.format('DD-MM-YYYY') }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
    import UseBaseArticleFunctions from './UseBaseArticleFunctions';
    import articleService from '@/services/http/article-service';
    import { watch, computed } from 'vue';

    const emit = defineEmits(['breadcrumbs']);
    const { articles, getModels } = UseBaseArticleFunctions();
    getModels();

    const articleIds = computed(() => articles.value.pluck('id').all());

    const articleChanged = function(article) {
        articleService.edit(article.id, {
            use_in_pricelist: article.useInPricelist
        })
    }

    const changeOrder = function (article, articleNewPositionId) {
        articleService.changePricelistOrder({
            from_article_id: article.id,
            to_article_id: articleNewPositionId
        }).then(() => {
            window.location.reload();
        });
    }

    emit('breadcrumbs', [
        {label: 'Prijzen', to: '/prices'},
        {label: 'Prijslijst', to: '/prices/list'},
        {label: 'Instellingen'},

    ]);
</script>
