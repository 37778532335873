<template>
    <div class="widget-holder">
        <h5>Active oogstmedewerkers</h5>
    </div>
</template>

<script setup>

    import HarvestAssignmentService from '@/services/http/harvest-assignment-service';
    const emit = defineEmits(['breadcrumbs']);
    emit('breadcrumbs', [
        {label: 'Insights', to: '/customer-insights'},
    ]);

    HarvestAssignmentService.get({
        filters: {
            'non_complete': 1
        },
        include: ['users.workSessions']
    }).then(users => {

    });

</script>
