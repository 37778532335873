import { ref, computed } from 'vue';
import baseArticleService from '@/services/http/base-article-service';
import articleService from '@/services/http/article-service';
import collect from 'collect.js';
import moment from 'moment';
import unitService from '@/services/http/unit-service';

export default function UseBaseArticleFunctions() {

    const baseArticles = ref(collect())
    const articles = ref(collect())
    const gramUnit = ref(null)


    // For every article, set the baseArticles that the article are build out of.
    // In addition, if an article is build out of a base article multiple times,
    // then, save the amount of times on the article aswell.
    const articlesWithBaseArticles = computed(() => {
        if (baseArticles.value.count() <= 0 || articles.value.count() <= 0 || ! gramUnit.value) {
            return collect();
        }

        articles.value.each(article => {
            article.baseArticles = baseArticles.value.filter((ba) => ba.compliesToArticle(article));
            article.baseArticleAmounts = [];

            // If article has base articles that should be counted multiple times, set these in the article.
            setAmountOfBaseArticlesOnArticle(article)
        });

        return articles.value;
    });

    // If article has base articles that should be counted multiple times, set these in the article.
    const setAmountOfBaseArticlesOnArticle = function(article) {
        // If the article has a base article with an articleId in it, count how many times it included.
        for (const baseArticle of article.baseArticles.whereNotNull('articleId')) {
            article.baseArticleAmounts.push({
                id: baseArticle.id,
                amount: article.timesArticleInBom(baseArticle.articleId)
            });
        }


        // If the article has a base article with kilo's, count how many kilo's the article is.
        for (const baseArticle of article.baseArticles.where('baseArticleGroup.name', 'Radijs per Kilo')) {
            let kilos = Math.round((article.getAmountOfGrams(gramUnit.value.id)/1000)*100)/100
            if (kilos >= 1) {
                kilos = Math.round(kilos*10)/10;
            }
            article.baseArticleAmounts.push({
                id: baseArticle.id,
                amount: kilos
            });
        }

        // If the article has a base article that is affected by the amount of content inside, set the content as amount.
        for (const baseArticle of article.baseArticles.where('baseArticleGroup.affectedByContent', true)) {
            article.baseArticleAmounts.push({
                id: baseArticle.id,
                amount: article.articleContentId
                    ? article.articleContentId
                    : article.contentId
            });
        }
    }


    // Get price and buildup of price of given article.
    // If otherPrices is not empty, use these prices instead of the articles one.
    const price = function(article, otherPrices = []) {
        let price = [];

        for (const baseArticle of article.baseArticles) {
            const baseArticleAmount = article.baseArticleAmounts.find(amount => amount.id === baseArticle.id)
            const amount = baseArticleAmount ? baseArticleAmount.amount : 1;

            const otherPrice = otherPrices.find(p => p.id === baseArticle.id);
            const cents = otherPrice ? otherPrice.cents : baseArticle.cents;

            price.push({
                coverSheet: baseArticle.coverSheetId !== null,
                name: baseArticle.name(),
                cents: cents,
                amount: amount,
                centsTotal: cents * amount
            });
        }

        return {
            totalCents: price.reduce((partialSum, a) => partialSum + a.centsTotal, 0),
            totalCentsWithoutCoversheet: price.filter(priceInfo => ! priceInfo.coverSheet).reduce((partialSum, a) => partialSum + a.centsTotal, 0),
            info: price
        };
    }

    const getArticles = function() {
        return articleService
            .get({
                filters: {order_by: 'pricelist_sequence', 'orderLines.order': {'id_is_not': 0}},
                include: ['latestOrderLine.order', 'childArticlesTree']
            }).then(articleModels => {
                articles.value = articleModels
                return articleModels;
            });
    }

    const getBaseArticles = function() {
        return baseArticleService
        .get({
            include: [
                'chest', 'size', 'type', 'coverSheet', 'article',
                'packaging', 'pallet', 'baseArticleGroup', 'latestBaseArticlePrice',
                'latestBeforeTodayBaseArticlePrice'
            ],
            includeFilter: {
                'baseArticlePrices': {
                    'created_at_is_greater_than': moment().subtract(2, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss')
                }
            }
        })
        .then(baseArticleModels => {
            baseArticles.value = baseArticleModels;
            return baseArticleModels;
        });
    }

    const unitPromise = ref(null);
    const getGramUnit = function() {
        unitPromise.value =  unitService
        .first({filters: {'name_is': 'gram'}})
        .then(unitModel => {
            gramUnit.value = unitModel
        });
    }
    getGramUnit();

    const getModels = function() {
        return Promise.all([getArticles(), getBaseArticles()]);
    }

    // Create a name out of all base articles.
    const name = function(baseArticles, contentId, times) {
        /* const name = [];
        const bosjesBaseArticle = baseArticles.first(baseArticle => baseArticle.baseArticleGroup.name === 'Bosjes');
        const kiloBaseArticle = baseArticles.first(baseArticle => baseArticle.baseArticleGroup.name === 'Radijs per Kilo');

        // Push packaging name
        const baseArticlePackaging = baseArticles.first(baseArticle => baseArticle['packagingId'] !== null);
        if (baseArticlePackaging) {
            name.push(kiloBaseArticle ? baseArticlePackaging.name() : baseArticlePackaging.packaging.name);
        }

        // Push chest name
        const baseArticleChest = baseArticles.first(baseArticle => baseArticle['chestId'] !== null);
        if (baseArticleChest) {
            name.push(baseArticleChest.name());
        }

        // Push article name
        const baseArticleArticles = baseArticles.filter(baseArticle => baseArticle['articleId'] !== null);
        if (baseArticleArticles) {
            for(const baseArticleArticle of baseArticleArticles) {
                if (baseArticleArticle.packagingId && ! baseArticleArticle.baseArticleGroup.affectedByKilo) {
                    name.push(
                        times.firstWhere('id', baseArticleArticle.id).times + 'x ' + baseArticleArticle.name()
                    )
                } else {
                    name.push(baseArticleArticle.name());
                }
            }
        }

        // Push type name
        const baseArticleType = baseArticles.first(baseArticle => (baseArticle['typeId'] !== null));
        if (baseArticleType && ! kiloBaseArticle) {
            name.push(baseArticleType.type.translate('name'));
        }

        // Push size name
        const baseArticleSize = baseArticles.first(baseArticle => (baseArticle['sizeId'] !== null));
        if (baseArticleSize && ! kiloBaseArticle) {
            name.push(baseArticleSize.size.translate('name'));
        }

        // Push pallet name
        const baseArticlePallet = baseArticles.first(baseArticle => baseArticle['palletId'] !== null);
        if (baseArticlePallet) {
            name.push(baseArticlePallet.name());
        }

        // Push content name
        if (contentId && contentId !== 28) {
            name.push('x' + contentId);
        }

        if (kiloBaseArticle) {
            name.push('(' + times.firstWhere('id', kiloBaseArticle.id).times + ' Kilo)')
        }

        return name.join(' '); */
    }

    /* // Sort baseArticleCombos: packaging -> type -> chest -> size
    const sortBaseArticleCombos = function(a, b) {
        for (const sortBy of [
            {attr: 'type', idAttr: 'typeId'},
            {attr: 'packaging', idAttr: 'packagingId'},
            {attr: 'chest', idAttr: 'chestId'},
            {attr: 'size', idAttr: 'sizeId'}
        ]) {
            const objA = a.baseArticles.first(a => a[sortBy.idAttr] !== null);
            const objB = b.baseArticles.first(b => b[sortBy.idAttr] !== null);

            if (objA && ! objB) {
                return sortBy.attr === 'packaging' ? 1 : -1;
            } else if (! objA && objB) {
                return sortBy.attr === 'packaging' ? -1 : 1;
            } else if (! objA && ! objB) {
                return 0;
            } else if (sortBy.attr !== 'type') {
                if (objA[sortBy.idAttr] > objB[sortBy.idAttr]) {
                    return 1
                } else if(objA[sortBy.idAttr] < objB[sortBy.idAttr]) {
                    return -1
                }
            } else {
                if (objA[sortBy.attr].sequence > objB[sortBy.attr].sequence) {
                    return 1
                } else if(objA[sortBy.attr].sequence < objB[sortBy.attr].sequence) {
                    return -1
                }
            }


        }
        return 0;
    } */

    return {

        // Variables
        baseArticles, articles,

        // Computed
        articlesWithBaseArticles,

        // Functions
        getArticles, getModels, price
    }
}
