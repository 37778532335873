<template>
    <div data-articles id="articles" class="container">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Artikelen</h1>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="tabs-holder">
                    <ul class="tabs">
                        <li @click="activeTab = 'articles'" :class="{active: activeTab === 'articles'}"><a>Verkoopartikelen</a></li>
                        <li @click="activeTab = 'harvestProducts'" :class="{active: activeTab === 'harvestProducts'}"><a>Oogstartikelen</a></li>
                        <li @click="activeTab = 'bulk'" :class="{active: activeTab === 'bulk'}"><a>Bulkartikelen</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <input class="form-control searchbar" placeholder="Zoeken.." type="text" v-model="search">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="articles-list">
                    <div
                        :class="{'has-image': article.image_url != null && article.imageUrl != ''}"
                        v-show="activeTab === 'articles'"
                        @click="edit = article"
                        class="articles-list-article-holder"
                        v-for="article of filteredArticles"
                        :key="article.id"
                    >
                        <div class="articles-list-name">
                            {{ article.name }}
                        </div>
                        <div class="articles-list-content">
                            {{ article.content ? article.content.name : '-' }}
                        </div>
                        <div class="articles-list-type">
                            {{ article.type ? article.type.translate('name') : '-' }}
                        </div>
                        <div class="articles-list-size">
                            {{ article.size ? article.size.translate('name') : '-' }}
                        </div>
                        <div class="articles-list-chest">
                            {{ article.chest ? article.chest.name : '-' }}
                        </div>
                    </div>
                </div>
                <div class="articles-list">
                    <div
                        :class="{'has-image': harvestProduct.image_url != null && harvestProduct.image_url != ''}"
                        v-show="activeTab === 'harvestProducts'"
                        @click="edit = harvestProduct"
                        class="articles-list-article-holder"
                        v-for="(harvestProduct, index) of filteredHarvestProducts"
                        :key="index"
                    >
                        <div class="articles-list-name">
                            {{ harvestProduct.name() }}
                        </div>
                    </div>
                </div>
                <div class="articles-list">
                    <div
                        :class="{'has-image': article.image_url != null && article.image_url != ''}"
                        v-show="activeTab === 'bulk'"
                        class="articles-list-article-holder"
                        v-for="(article, index) of filteredBulkArticles"
                        :key="index"
                        @click="editBulkArticle = article"
                    >
                        <ArticleName :article="article" />
                    </div>
                </div>
            </div>
        </div>

        <EditArticleModal
            @updated="url => edit.image_url = url"
            v-if="edit"
            @close="edit = false"
            :article="edit"
            :productImages="productImages"
        />

        <EditBulkArticleModal
            :article="editBulkArticle"
            v-if="editBulkArticle"
            @close="editBulkArticle = null"
            @saved="bulkArticleUpdated()"
        />
    </div>
</template>

<script setup>
    import Article from './../../../models/article.js';
    import HarvestProduct from './../../../models/harvest-product.js';
    import EditArticleModal from './List/EditArticleModal.vue';
    import EditBulkArticleModal from './List/EditBulkArticleModal.vue';
    import { useCcstStore } from '../../../stores/ccst';
    import { computed, ref } from 'vue';
    import ArticleName from './List/ArticleName.vue';
    import { useToast } from 'vue-toast-notification';

    const emit = defineEmits(['breadcrumbs']);

    const ccst = useCcstStore();
    const activeTab = ref('articles');

    const edit = ref(false);
    const productImages = ref([]);
    const search = ref('');


    /*
    |--------------------------------------------------------------------------
    | Articles
    |--------------------------------------------------------------------------
    */
    const articles = ref([]);
    const filteredArticles = computed(() => {
        return articles.value.filter(article => {
            return article.name.toLowerCase().includes(search.value.toLowerCase());
        })
    });

    /*
    |--------------------------------------------------------------------------
    | Harvest products
    |--------------------------------------------------------------------------
    */
    const harvestProducts = ref([]);
    const filteredHarvestProducts = computed(() => {
        return harvestProducts.value.filter(harvestProduct => {
            return harvestProduct.name().toLowerCase().includes(search.value.toLowerCase());
        })
    });

    /*
    |--------------------------------------------------------------------------
    | Bulk articles
    |--------------------------------------------------------------------------
    */
    const editBulkArticle = ref(null)
    const filteredBulkArticles = computed(() => {
        return articles.value.filter(bulkArticle => (
            bulkArticle.content_id == 28
            && bulkArticle.name.toLowerCase().includes(search.value.toLowerCase())
        ));
    });

    const bulkArticleUpdated = function() {
        getArticles();
        editBulkArticle.value = null;
        useToast().success('Conversion rate gewijzigd');
    }

    /*
    |--------------------------------------------------------------------------
    | Parse data
    |--------------------------------------------------------------------------
    */
    const setData = function()
    {
        for (let article of articles.value) {
            if (
                article.chest_id != null && article.content_id != null && article.size_id != null && article.type_id != null
                && ! harvestProducts.value.find(harvestProduct => (
                    harvestProduct.chest_id == article.chest_id
                    && harvestProduct.content_id == article.content_id
                    && harvestProduct.size_id == article.size_id
                    && harvestProduct.type_id == article.type_id
                ))
            ) {
                let harvestProduct = new HarvestProduct();
                harvestProduct.setAttributesFromResponse({
                    id: null,
                    chest_id: article.chest_id,
                    content_id: article.content_id,
                    size_id: article.size_id,
                    type_id: article.type_id,
                    image_url: null,

                    chest: {data: [ccst.chests.find(chest => chest.id == article.chest_id)]},
                    content: {data: [ccst.contents.find(content => content.id == article.content_id)]},
                    size: {data: [ccst.sizes.find(size => size.id == article.size_id)]},
                    type: {data: [ccst.types.find(type => type.id == article.type_id)]},
                });

                harvestProducts.value.push(harvestProduct);
            }
        }

        if (harvestProducts.value.length > 0) {
            harvestProducts.value.sort((a, b) => {
                if (a.name() < b.name())
                    return -1;

                if (a.name() > b.name())
                    return 1;

                return 0;
            });
        }
    }



    /*
    |--------------------------------------------------------------------------
    | Getters (api)
    |--------------------------------------------------------------------------
    */

    const getArticles = function(executeSetData = true){
        return axios.get('/articles?include=content,size,chest,type&order=name')
        .then(response => {
            articles.value = [];
            for (let articleData of response.data.data) {
                let article = new Article();
                article.setAttributesFromResponse(articleData);
                articles.value.push(article);
            }

            if (executeSetData) {
                setData();
            }
        });
    }

    Promise.all([
        getArticles(true),
        axios.get('/harvest-products?include=content,size,chest,type')
        .then(response => {
            for (let harvestProductData of response.data.data) {
                let harvestProduct = new HarvestProduct();
                harvestProduct.setAttributesFromResponse(harvestProductData);
                harvestProducts.value.push(harvestProduct);
            }
        }),
        ccst.promise,
        axios.get('/files/list').then(response => {productImages.value = response.data;})
    ]).then(() => {
        setData();
    });

    emit('breadcrumbs', [
        {label: 'Instellingen', to: '/settings'},
        {label: 'Artikelen'}
    ])
</script>
