<template>
    <MultiSelect
        v-model="modelValueAsArray"
        @change="selected()"
        :placeholder="placeholder"
        :includeSearch="includeSearch"
        :options="props.options"
        :numberOfSelectedOptionsShow="1"
        :single="true"
    />
</template>

<script setup>
    import MultiSelect from './MultiSelect.vue';
    import { ref, watch } from 'vue'

    const emit = defineEmits(['update:modelValue'])
    const props = defineProps({
        modelValue: {},
        options: Array,
        placeholder: {
            type: String,
            default: 'Select een optie'
        },
        includeSearch: {
            type: Boolean,
            default: true
        }
    });

    const modelValueAsArray = ref(props.modelValue ? [props.modelValue] : []);
    const modelValue = ref(props.modelValue);

    watch(() =>
        modelValueAsArray.value
    , (newValue, oldValue) => {
        if (modelValueAsArray.value.length === 0 && newValue.length !== oldValue.length) {
            selected();
        }
    });

    const selected = function() {
        const newValue = modelValueAsArray.value.length === 0 ? null : modelValueAsArray.value[modelValueAsArray.value.length-1]

        modelValue.value = newValue;
        modelValueAsArray.value = newValue ? [newValue] : [];

        emit('update:modelValue', modelValue.value);
    }
</script>
