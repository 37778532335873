<template>
    <div
        :data-harvest-assignment-id="harvestAssignment['id']"
        v-if="! harvestAssignment.done() || harvestAssignment.users.filter(user =>
            user.id in workingOn
            && workingOn[user.id].id === harvestAssignment.id
            && user.pivot.skipped !== 1
        ).count() > 0 || ! harvestAssignment.locationId"
    >

    <!-- -->

        <div class="create-harvest-between new-harvest-assignment" v-if="harvestAssignment.new !== 1" @click="$emit('createOnPlace', harvestAssignment.id, harvestAssignment.rush)"></div>

        <div
            v-draggable
            class="harvest-assignment"
            :class="{'mini': (harvestAssignment.size && harvestAssignment.size.name == 'mini'), 'done': harvestAssignment.done() }"
            :data-harvest-assignment="harvestAssignment['id']"
            :data-size-id="harvestAssignment.sizeId"
            :data-content-id="harvestAssignment.contentId"
            :data-chest-id="harvestAssignment.chestId"
            :data-type-id="harvestAssignment.typeId"
            :data-location-id="harvestAssignment.locationId"
            :data-complete="harvestAssignment.harvested >= harvestAssignment.amount ? 1 : 0"
        >
            <div @click="$emit('edit')" :data-id="harvestAssignment.id" class="harvest-assignment-row" :class="{'has-active-users': Object.values(workingOn).map(wo => wo.id).includes(harvestAssignment.id)}">
                <div class="row no-gutters align-items-center">

                    <div class="col-12 col-md-5">
                        <div class="harvest-assignment-customer">
                            {{ harvestAssignment.name() }}
                        </div>
                    </div>

                    <div class="col-12 col-md-5 text-center">
                        <div class="harvest-assignment-employees">
                            <div
                                v-on:click.stop="(! harvestAssignment.size || harvestAssignment.size.name != 'mini') ? $emit('forceHarvestAssignment', user, harvestAssignment) : ''"
                                v-tooltip="user.name()"
                                class="harvest-assignment-employee"
                                :class="{
                                    activated: (user.id in workingOn && workingOn[user.id].id === harvestAssignment.id),
                                    skipped: user.pivot.skipped === 1
                                }"
                                v-for="(user, eIndex) of harvestAssignment.users"
                                :key="eIndex"
                            >
                                <span class="employee-initials" v-tooltip="user.name">{{ user.initials() }}</span>
                                <img :src="user.avatars && user.avatars.length > 0 ? user.avatar('small').public_url : '/img/profile-placeholder.jpg'" />
                                <div v-on:click.stop="$emit('editHarvestedOfUser', user, harvestAssignment)" class="harvested-amount" v-if="user.pivot.startsAt !== 0">
                                    {{
                                        harvestAssignment.harvestRegistrations.filter(hr => hr.userId === user.id).count() > 0
                                        ? harvestAssignment.harvestRegistrations.filter(hr => hr.userId === user.id).map(item => item.harvested).reduce((prev, next) => prev + next)
                                        : '-'
                                    }}
                                </div>
                            </div>
                            <div class="harvest-assignment-location">
                                <span v-if="harvestAssignment.location">{{ harvestAssignment.location.name.substring(0, 2) }}</span>
                                <span v-else class="no-location" v-tooltip="'Locatie onbekend'">?</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-2">
                        <div class="harvest-assignment-progress">
                            <span>
                                {{ harvestAssignment.harvested }} / {{ harvestAssignment.amount }}
                            </span>
                            <div class="harvest-progress" v-if="harvestAssignment.new != 1">
                                <span>{{ harvestAssignment.completedAt !== undefined ? harvestAssignment.completedAt.format('HH:mm') : '-' }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div v-if="harvestAssignment.new" class="new-harvest-assignment-buttons-holder">
                            <div class="move-harvest-assignment-button rush" @click.stop="useHarvestAssignmentStore().move(harvestAssignment.id, lastRushId, true)">
                                <Icon name="chevron-double-up" type="solid" />
                            </div>
                            <div class="move-harvest-assignment-button normal" @click.stop="useHarvestAssignmentStore().move(harvestAssignment.id, lastNormalId, false)">
                                <Icon name="chevron-up" type="solid" />
                            </div>
                        </div>
                    </div>

                    <div class="harvest-assignment-technical-details">
                        <span>{{ harvestAssignment.sequence }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useHarvestAssignmentStore } from '../../stores/harvestAssignments';
    import { ref, computed, watch } from 'vue';

    const props = defineProps(['harvestAssignment', 'locationPerUser', 'time', 'last', 'authUser', 'edditedId', 'lastRushId', 'lastNormalId']);

    const prevUserLocations = ref({});

    const store = useHarvestAssignmentStore();

    const workingOn = computed(() => store.workingOn);
</script>
