<template>
    <SideModal :noFade="true" class="edit-harvest-assignment" @close="close()" @save="save()">
        <template v-slot:header>
            <span>{{ harvestAssignment.name() }}</span>
        </template>

        <span>
            <form @submit.prevent enctype="multipart/form-data">

                <!-- Locations -->
                <div class="harvest-locations">
                    <label v-show="show_all || location.default" v-for="location of locations" :key="location.id" :for="location.id">
                        <input type="radio" :id="location.id" :value="location.id" v-model="new_location_id">
                        <span>{{ location.name.substring(0, 2) }}</span>
                    </label>
                </div>
                <input v-show="! show_all" type="button" class="show-more-button" value="Alle locaties tonen" @click="show_all = true">

                <div class="users-search-holder">
                    <input class="form-control" type="text" placeholder="Medewerker zoeken" v-model="search">
                </div>

                <!-- Users -->
                <div class="users">
                    <div class="row header">
                        <div class="col-4">Naam</div>
                        <div class="col-3 text-center">Locatie</div>
                        <div class="col-3 text-center" v-tooltip="'Deze tijd geeft aan wanneer de medewerker kan starten met de bosopdracht.'">Starttijd</div>
                        <div class="col-2 text-center" v-tooltip="'Persoonlijke aandeel van de oogst van dit product.'">%</div>
                    </div>
                    <div class="user-list">
                        <div
                            @click="toggleUserId(user.id)"
                            v-for="user of sortedUsers.filter(user => user.roles.first(role => role.name == (harvestAssignment.size.name == 'mini' ? 'minis-harvest-worker' : 'harvest-worker')))"
                            :key="user.id"
                            :class="{selected: (user_ids.filter(ha_user_id => ha_user_id === user.id).count() > 0), 'is-working': user.workSessions.count() > 0}" class="row user"
                        >
                            <div class="col-4 user-name">{{ user.name() }}</div>

                            <div v-if="today && user.id in workingOn && workingOn[user.id].location" class="col-3 location">{{ (user.id in workingOn && workingOn[user.id].location) ? workingOn[user.id].location.name : '-' }}</div>
                            <div v-else class="col-3 location">{{ user.id in firstLocationsOfDay ? locations.filter(location => location.id == firstLocationsOfDay[user.id])[0].name : '-' }}</div>
                            <template v-if="'userStartTimes' in harvestAssignment">
                                <div v-if="today" class="col-3 starttime">{{ harvestAssignment['userStartTimes'][user.id] ? harvestAssignment['userStartTimes'][user.id].format('HH:mm') : '-' }}</div>
                                <div v-else class="col-3 starttime">{{ harvestAssignment['userStartTimes'][user.id] ? harvestAssignment['userStartTimes'][user.id].format('HH:mm') : '00:00' }}</div>
                            </template>
                            <div class="col-2 starttime">
                                {{ hsUsers.find(hsUser => hsUser.user.id == user.id) !== undefined ? Math.round(hsUsers.find(hsUser => hsUser.user.id == user.id).amount) + '%' : '-' }}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </span>

        <template v-slot:buttons>
            <button @click="emits('remove', harvestAssignment.id)" class="btn btn-outline-danger" v-text="harvestAssignment.harvested ? 'Afronden' : 'Verwijderen'"></button>
        </template>
    </SideModal>
</template>

<script setup>
    import { useHarvestAssignmentStore } from '../../stores/harvestAssignments';
    import { useCalculateHarvestSchedule } from '../../composables/useCalculateHarvestSchedule';
    import SideModal from './SideModal.vue';
    import { ref, computed, watch, onUnmounted } from 'vue';


    const props = defineProps(['harvestAssignment','today', 'firstLocationsOfDay', 'locations', 'users', 'locationPerUser']);
    const emits = defineEmits(['close', 'remove']);
    const show_all = ref(true);
    const new_location_id = ref();
    const user_ids = ref([]);
    const original_user_ids = ref([]);
    const hsUsers = ref([]);
    const search = ref('');

    const harvestAssignmentStore = useHarvestAssignmentStore();

    const workingOn = computed(() => harvestAssignmentStore.workingOn);

    const sortedUsers = computed(() => {
        let users = _.cloneDeep(props.users);

        if (search.value !== '') {
            users = users.filter((u) => u.name().toLowerCase().includes(search.value.toLowerCase()));
        }

        return users.sort((a, b) => {
            if (a.firstname > b.firstname) {
                return 1;
            }

            return -1;
        });
    });


    const save = function() {
        let removed_ids = original_user_ids.value.diff(user_ids.value);
        let new_ids = user_ids.value.diff(original_user_ids.value);

        let data = {};

        if (removed_ids.count() > 0 ) {
            data['removed_user_ids'] = removed_ids;
        }

        if (new_ids.count() > 0 ) {
            data['new_user_ids'] = new_ids;
        }

        if (props.harvestAssignment.location_id !== new_location_id.value) {
            data['location_id'] = new_location_id.value;
        }

        if (! _.isEmpty(data)) {
            harvestAssignmentStore.updateHarvestAssignment(props.harvestAssignment.id, data);
        }

        close();
    };

    const close = function() {
        emits('close');
        document.querySelector('body').classList = '';
    };

    const origininalHarvestAssignment = _.cloneDeep(props.harvestAssignment);
    const toggleUserId = function(user_id) {
        let index = user_ids.value.search(id => user_id === id);

        if (index !== false) {
            user_ids.value.splice(index, 1)
        } else {
            user_ids.value.push(user_id);
        };

        const alteredHarvestAssignment = _.cloneDeep(origininalHarvestAssignment);

        const users = alteredHarvestAssignment.users.filter(u => user_ids.value.contains(u.id)).merge(
            props.users.filter(u =>
                ! alteredHarvestAssignment.users.pluck('id').contains(u.id)
                && user_ids.value.contains(u.id)
            ).map((u) => {
                u.pivot = {
                    harvest_assignment_id: props.harvestAssignment.id,
                    skipped: 0,
                    starts_at: null
                }
                return u;
            })

        );

        alteredHarvestAssignment.users = users;
        //useHarvestAssignmentStore().setAlteredHarvestAssignment(alteredHarvestAssignment);
    }

    const clone = function(obj) {
        if (null == obj || "object" != typeof obj) return obj;
        var copy = obj.constructor();
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
    }

    const { data, calculateHarvestSchedules } = useCalculateHarvestSchedule();

    calculateHarvestSchedules(_.map(props.users, 'id')).finally(() => {
        setCurrentHsUsers();
    });


    watch(() => props.harvestAssignment, (a, b) => {
        setHarvestAssignmentVariables();
        setCurrentHsUsers();
    });

    const setCurrentHsUsers = function() {
        let content = _.find(data.value, {id: props.harvestAssignment.contentId});
        if (content !== undefined) {
            let type = _.find(content.types, {id: props.harvestAssignment.typeId});

            if (type !== undefined) {
                hsUsers.value = type.users;
            }
        }
    };

    const setHarvestAssignmentVariables = function() {
        original_user_ids.value = props.harvestAssignment.users.map(user => user.id);
        user_ids.value = props.harvestAssignment.users.map(user => user.id);
        new_location_id.value = props.harvestAssignment.locationId;
    }
    setHarvestAssignmentVariables();

    show_all.value = (props.harvestAssignment.location && props.harvestAssignment.location.default === 0);

    onUnmounted(() => {
        useHarvestAssignmentStore().setAlteredHarvestAssignment(null)
    });


</script>
