import axios from "axios";
import { collect } from "collect.js";

export class AbstractHttpService {
    getById(id, parameters = {}) {
        let url = this.makeUrl(parameters, id);

        return this.sendRequest('get', url).then(obj => obj ? obj[0] : null);
    }

    first(parameters) {
        let url = this.makeUrl(parameters, 'first');

        return this.sendRequest('get', url).then(obj => obj ? obj[0] : null);
    }

    get(parameters = {}) {
        let url = this.makeUrl(parameters);

        return this.sendRequest('get', url).then(objs => collect(objs));
    }

    delete(id, parameters = {}) {
        let url = this.makeUrl(parameters, id);

        return this.sendRequest('delete', url).then(obj => obj ? obj[0] : null);
    }

    create(payload, parameters = {}) {
        let url = this.makeUrl(parameters);

        return this.sendRequest('post', url, payload).then(obj => obj ? obj[0] : null);
    }

    edit(id, payload = {}, parameters = {}) {
        let url = this.makeUrl(parameters, id);
        return this.sendRequest('put', url, payload).then(obj => obj ? obj[0] : null);
    }

    sendGetRequest(url, parameters) {
        url = this.makeUrl({}, url);

        return axios.get(url, {
            params: parameters
        }).then(data => {
            return data.data;
        });
    }

    makeUrl(parameters, urlExtra = '') {
        let url = `/v2/${this.endpoint()}`;
        let queryString = this.parseParametersToQueryString(parameters);

        if (urlExtra !== '' || 'urlExtra' in parameters) {
            url += `/${urlExtra !== '' ? urlExtra : parameters['urlExtra']}`;
        }


        if (queryString !== '') {
            url += `?${queryString}`;
        }

        return url;
    }

    parseParametersToQueryString(parameters) {
        let queryString = [];

        if ('filters' in parameters) {
            for (let key of Object.keys(parameters['filters'])) {
                queryString.push(this.parseFilter(key, parameters['filters'][key]));
            }
        }

        if ('include' in parameters) {
            queryString.push('with=' + parameters['include'].filter(i => ! (i instanceof Object)).join(','));
        }

        if ('includeFilter' in parameters) {
            for (let key of Object.keys(parameters['includeFilter'])) {
                queryString.push(this.parseFilter(key, parameters['includeFilter'][key], 'include'));
            }
        }

        return queryString.join('&');
    }

    parseFilter(key, value, filterString = 'filter') {
        if (value instanceof Object) {
            let childFilters = [];
            for (let childKey of Object.keys(value)) {
                childFilters.push(this.parseFilter(childKey, value[childKey], `${filterString}[${key}]`));

            }
            return childFilters.join('&');
        }

        return `${filterString}[${key}]=${value}`;
    }

    sendRequest(method, url, payload = {}) {
        let request = null;
        if(['put', 'post', 'delete'].indexOf(method) >= 0) {
            request = axios[method](url, payload);
        } else {
            request = axios.get(url)
        }

        return request.then(data => {
            if (Array.isArray(data.data.data)) {
                let models = [];
                for (let modelData of data.data.data) {
                    models.push(this.createModel(modelData));
                }

                return models;
            }

            return this.createModel(modelData);
        });
    }

}
