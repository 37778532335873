import { AbstractModel } from './abstract-model';
import BaseArticle from './base-article';

export default class BaseArticlePrice extends AbstractModel {

    getMutators()
    {
        return {
            'createdAt': 'datetime'
        }
    }

    relations() {
        return {
            baseArticle: BaseArticle
        }
    }

    relationPlural()
    {
        return {
            baseArticle: false
        }
    }

}
