import { AbstractModel } from './abstract-model';
import Article from './article';
import BaseArticleGroup from './base-article-group';
import BaseArticlePrice from './base-article-price';
import Chest from './chest';
import CoverSheet from './cover-sheet';
import Packaging from './packaging';
import Size from './size';
import Pallet from './pallet';
import Type from './type';

export default class BaseArticle extends AbstractModel {

    name()
    {
        let name = [];

        if (this.article) {
            return this.article.name;
        }

        if (this.packaging) {
            name.push(this.packaging.name);
        }

        if (this.type) {
            name.push(this.type.translate('name'));
        }

        if (this.size) {
            name.push(this.size.translate('name'));
        }

        if (this.chest) {
            name.push(this.chest.name);
        }

        if (this.special) {
            name.push(this.special);
        }

        if (this.coverSheet) {
            name.push(this.coverSheet.name);
        }

        if (this.pallet) {
            name.push(this.pallet.name);
        }


        return name.join(' ');
    }

    compliesToArticle(article) {
        // Set all attributes the given article should comply to.
        // If an attribute is not set on this base article, ignore the attribute.
        let complyAttributes = ['chestId', 'sizeId', 'typeId', 'coverSheetId', 'articleId', 'packagingId', 'palletId'];
        complyAttributes = complyAttributes.filter(attribute => this[attribute]);

        // if no comply attributes remain, return false.
        if (complyAttributes.length === 0) {
            return false;
        }

        // Check if given article does not comply to all of the set complyAttributes.
        // Otherwise return true.
        for (const attribute of complyAttributes) {
            if (
                article.packagingId === 3 && this.baseArticleGroup.name === 'Bosjes'
                || attribute !== 'chestId' && attribute !== 'articleId' && this[attribute] !== article[attribute]
                || attribute === 'chestId' && (
                    article['articleChestId'] && article['articleChestId'] !== this['chestId']
                    || ! article['articleChestId'] && article['chestId'] !== this['chestId']
                )
                || attribute === 'articleId' && ! article.hasArticleInBom(this[attribute])
            ) {
                return false;
            }
        }

        return true;
    }

    getMutators()
    {
        return {
            updatedAt: 'datetime'
        }
    }

    relations() {
        return {
            baseArticleGroup: BaseArticleGroup,
            chest: Chest,
            size: Size,
            type: Type,
            coverSheet: CoverSheet,
            article: Article,
            pallet: Pallet,
            packaging: Packaging,
            baseArticlePrices: BaseArticlePrice,
            latestBaseArticlePrice: BaseArticlePrice,
            latestBeforeTodayBaseArticlePrice: BaseArticlePrice
        }
    }

    relationPlural()
    {
        return {
            baseArticleGroup: false,
            chest: false,
            size: false,
            type: false,
            pallet: false,
            coverSheet: false,
            article: false,
            packaging: false,
            baseArticlePrices: true,
            latestBaseArticlePrice: false,
            latestBeforeTodayBaseArticlePrice: false,
        }
    }

}
