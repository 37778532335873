<template>
    <div class="container">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Artikelcodes game</h1>
                </div>
            </div>
        </div>

        <div class="article-codes-game-holder">

            <div class="out-of-articles" v-if="articles.count() === 0 && articleToGuess === null">
                Je hebt alle artikelen gehad, tijd voor een bakkie?
            </div>

            <div class="guess-article-holder" :class="{correct: correctAnswer === true, incorrect: correctAnswer === false}" v-if="articleToGuess">
                <div class="guess-article-name">
                    <h3>{{ articleToGuess.name }}</h3>
                </div>

                <div class="guess-article-image">
                    <img :src="articleToGuess.imageUrl ? articleToGuess.imageUrl : 'https://s3.eu-central-1.amazonaws.com/jongfresh-dev/products/images/jongfresh_kist.png'" />
                </div>

                <div class="feedback-positive" v-if="correctAnswer === true">
                    Dat is de goede artikelcode!
                </div>
                <div class="feedback-negative" v-if="correctAnswer === false && tries > 0">
                    Dit is niet het juiste artikelnummer, je hebt nog {{ tries }} poging{{ tries > 1 ? 'en' : '' }} over.
                </div>
                <div class="feedback-game-over" v-if="correctAnswer === false && tries === 0">
                    Helaas, misschien lukt het bij de volgende! Het goede antwoord was {{ articleToGuess.externalCode }}
                </div>

                <div style="margin-bottom: 50px;">
                    <input class="guess-article-input" type="text" v-model="answer" placeholder="Wat is het artikelnummer?">
                    <a class="button success" v-if="tries >= 1 && correctAnswer !== true" @click="checkAnswer">Controleren</a>
                    <a class="button success" v-if="tries <= 0 || correctAnswer" @click="setArticleToGuess">Volgende artikel!</a>
                </div>

                <div class="given-answer" v-for="givenAnswer of givenAnswers">
                    {{ givenAnswer }}
                </div>

                <!-- <span style="color: #eee; margin: 50px 0;">
                    {{  articleToGuess.externalCode }}
                </span> -->
            </div>


            <div v-if="guessedArticles.count() > 0" style="margin-top: 50px; width: 80%;">

                <h3>Resultaten</h3>
                <div class="row" style="border-bottom: 1px solid #999;">
                    <div class="col-8"><b>Artikel</b></div>
                    <div class="col-2"><b>Pogingen</b></div>
                    <div class="col-2"><b>Uitslag</b></div>
                </div>

                <div class="row" v-for="article of guessedArticles" style="border-bottom: 1px solid #ccc; padding: 5px 0;">
                    <div class="col-8">{{ article.article.name }}</div>
                    <div class="col-2">{{ article.tries }}</div>
                    <div class="col-2">{{ article.correct ? 'Correct!' : (article.correct === false ? 'Niet gelukt' : 'Niet beantwoord') }}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
    import articleService from '@/services/http/article-service';
    import collect from 'collect.js';
    import moment from 'moment';
    import { ref } from 'vue'

    const articles = ref(collect());
    const articleToGuess = ref(null);
    const tries = ref(3);
    const correctAnswer = ref(null);
    const guessedArticles = ref(collect());
    const answer = ref('')
    const givenAnswers = ref([]);

    const getArticles = function() {
        articleService.get({
            filters: {
                0: 'sellable',
                'orderLines.order': {'from': moment().subtract(3, 'month').format('YYYY-MM-DD')},
                1: 'pallet_id_is_null'
            }
        }).then(articleModels => {
            articles.value = articleModels;
            setArticleToGuess();
        });
    }
    getArticles();

    const setArticleToGuess = function() {
        if (articleToGuess.value) {
            guessedArticles.value.push({
                article: articleToGuess.value,
                tries: 3 - tries.value,
                correct: correctAnswer.value,
                answer: answer.value
            });
            givenAnswers.value = [];
            articles.value = articles.value.filter(a => a.id !== articleToGuess.id);
        }

        tries.value = 3;
        correctAnswer.value = null;
        answer.value = '';

        articleToGuess.value = articles.value.random();
    }

    const checkAnswer = function() {
        correctAnswer.value = (articleToGuess.value.externalCode.toLowerCase() === answer.value.toLowerCase())
        tries.value--
        givenAnswers.value.push(answer.value);
    }
</script>
