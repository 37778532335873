<template>
    <div id="sow_settings">

        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="page-header">
                        <h1>Zaaiplanning instellingen</h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="sow-planning-settings">
                        <div class="widget-holder">
                            <div class="row">
                                <div class="col">
                                    <h2>
                                        Trend instellingen
                                    </h2>
                                    <div class="header-fab-button-holder">
                                        <fab-button :class="{danger:weeklyTrendEdit}" @click.native="weeklyTrendEdit = ! weeklyTrendEdit" :icon="! weeklyTrendEdit ? 'pencil' : 'x-mark'"></fab-button>
                                        <fab-button class="success" @click.native="updateWeeklyTrend()" v-if="weeklyTrendEdit" icon="check"></fab-button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label>
                                    <span class="styled-label">Aantal weken</span>
                                    <input class="styled-input" v-if="weeklyTrendEdit" type="number" v-model="weeklyTrend">
                                    <div v-else style="width: 136px; text-align: center">{{ weeklyTrend }}</div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="sow-planning-settings">
                    <div class="widget-holder">

                        <div class="row">
                            <div class="col">
                                <h2>
                                    Teeltkalender
                                </h2>
                                <div class="header-fab-button-holder">
                                    <fab-button :class="{danger:cultivationCalendarEdit}" @click.native="cultivationCalendarEdit ? resetCultivationCalendar() : (cultivationCalendarEdit = ! cultivationCalendarEdit)" :icon="! cultivationCalendarEdit ? 'pencil' : 'x-mark'"></fab-button>
                                    <fab-button class="success" @click.native="updateCultivationCalendar()" v-if="cultivationCalendarEdit" icon="check"></fab-button>
                                </div>
                            </div>
                        </div>
                        <div class="cultivation-calendar">
                            <table class="styled-table">
                                <tbody class="styled-table-body">
                                    <tr>
                                        <td v-for="(cultivationCalendarDay, index) of cultivationCalendar" :key="index" class="text-center">
                                            <div style="width: 60px">
                                                <b>{{ cultivationCalendarDay.sowingDate.format('DD-MM') }}</b>
                                                <div v-if="cultivationCalendarEdit">
                                                    <input class="styled-input" style="width: 50px; text-align:center;" type="number" v-model="cultivationCalendarDay.days">
                                                </div>
                                                <div v-else>{{ cultivationCalendarDay.days }}</div>
                                                <div>{{ cultivationCalendarDay.sowingDate.clone().add(cultivationCalendarDay.days, 'day').format('DD-MM') }}</div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="sow-planning-settings">
                    <div class="widget-holder">

                        <div class="row">
                            <div class="col">
                                <h2>Zaden per M2</h2>
                                <div class="header-fab-button-holder">
                                    <fab-button :class="{danger:seedsPerSquaredMeterEdit}" @click.native="seedsPerSquaredMeterEdit ? resetSeedsPerSquaredMeter() : (seedsPerSquaredMeterEdit = ! seedsPerSquaredMeterEdit)" :icon="! seedsPerSquaredMeterEdit ? 'pencil' : 'x-mark'"></fab-button>
                                    <fab-button class="success" @click.native="updateSeedsPerSquaredMeter()" v-if="seedsPerSquaredMeterEdit" icon="check"></fab-button>
                                </div>
                            </div>
                        </div>
                        <div style="overflow-x: scroll; scroll-snap-type: x mandatory; position: relative;">
                            <div v-if="ready" style="white-space:nowrap">

                                <div style="display:inline-block; vertical-align:top; width: 160px; line-height: 36px; border-right:2px solid black; scroll-snap-align: start; position: sticky; left: 0; background-color: #f7f7f7;">
                                    <div style="border-bottom: 1px solid transparent">&nbsp;</div>
                                    <div v-for="(type, index) of types" :key="index" style="border-bottom: 1px solid #ccc">
                                        <b>{{ type.translate('name') }}</b>
                                    </div>
                                </div>

                                <div v-for="week in 53" :key="week"  style="display:inline-block; vertical-align:top; width: 70px; line-height: 36px; border-right:1px solid #999; scroll-snap-align: start;">
                                    <div class="text-center" style="border-bottom: 1px solid #666; font-weight: 600;">
                                        {{ week }}
                                    </div>
                                    <div class="text-center" v-for="(type, index) of types" :key="index" style="border-bottom: 1px solid #ccc; ">
                                        <div v-if="seedsPerSquaredMeterEdit">
                                            <input
                                                class="styled-input text-center"
                                                style="line-height: 24px; padding: 4px"
                                                type="number"
                                                v-model="seedsPerSquaredMeters.where('weekNumber', week).firstWhere('typeId', type.id).seeds"
                                            >
                                        </div>
                                        <div v-else>{{ seedsPerSquaredMeters.where('weekNumber', week).firstWhere('typeId', type.id).seeds > 0 ? seedsPerSquaredMeters.where('weekNumber', week).firstWhere('typeId', type.id).seeds : '-' }}</div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="sow-planning-settings">
                    <div class="widget-holder">

                        <div class="row">
                            <div class="col">
                                <h2>Ras</h2>
                                <div class="header-fab-button-holder">
                                    <fab-button :class="{danger:breedPerTypeEdit}" @click.native="breedPerTypeEdit ? resetBreedPerType() : (breedPerTypeEdit = ! breedPerTypeEdit)" :icon="! breedPerTypeEdit ? 'pencil' : 'x-mark'"></fab-button>
                                    <fab-button class="success" @click.native="updateBreedPerType()" v-if="breedPerTypeEdit" icon="check"></fab-button>
                                </div>
                            </div>
                        </div>
                        <div style="overflow-x: scroll; scroll-snap-type: x mandatory; position: relative;">
                            <div v-if="ready && breedPerTypes" style="white-space:nowrap">

                                <div style="display:inline-block; vertical-align:top; width: 160px; line-height: 36px; border-right:2px solid black; scroll-snap-align: start; position: sticky; left: 0; background-color: #f7f7f7;">
                                    <div style="border-bottom: 1px solid transparent">&nbsp;</div>
                                    <div v-for="(type, index) of types.whereIn('id', breeds.pluck('typeId').unique().all())" :key="index" style="border-bottom: 1px solid #999">
                                        <div style="font-weight: 600">
                                            {{ type.translate('name') }}
                                        </div>
                                        <div style="height:24px">
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>

                                <div v-for="week in 53" :key="week"  style="display:inline-block; vertical-align:top; width: 100px; line-height: 36px; border-right:1px solid #999; scroll-snap-align: start;">
                                    <div class="text-center" style="border-bottom: 1px solid #999">
                                        <span style="font-weight: 600">
                                            {{ week }}
                                        </span>
                                    </div>
                                    <div v-for="(type, index) of types.whereIn('id', breeds.pluck('typeId').unique().all())" :key="index">

                                        <div class="text-center">
                                            <div style="height: 24px;" v-if="breedPerTypeEdit">
                                                <select v-model="breedPerType(week, type.id).breedId">
                                                    <option v-if="! breedPerType(week, type.id).breedId" :value="null">-</option>
                                                    <option v-for="(breed, index) of breeds.where('typeId', type.id)" :key="index" :value="breed.id">
                                                        {{ breed.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="text-center" style="padding: 0 4px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" v-else>
                                                {{ breedPerType(week, type.id).breedId ? breeds.firstWhere('id', breedPerType(week, type.id).breedId).name : '-' }}
                                            </div>
                                        </div>

                                        <div class="text-center" style="border-bottom: 1px solid #ccc">
                                            <div v-if="breedPerTypeEdit">
                                                <select v-model="breedPerType(week, type.id).fractionId">
                                                    <option v-if="! breedPerType(week, type.id).fractionId" :value="null">-</option>
                                                    <option v-for="(fraction, index) of fractions" :key="index" :value="fraction.id">
                                                        {{ fraction.milimeters }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div v-else style="line-height:24px; font-size: 0.8rem; color: #666">
                                                {{ breedPerType(week, type.id).fractionId ? fractions.firstWhere('id', breedPerType(week, type.id).fractionId).milimeters : '-'  }}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import settingService from '../../../services/http/setting-service';
    import cultivationCalendarDayService from '../../../services/http/cultivation-calendar-day-service';
    import breedPerTypeService from '../../../services/http/breed-per-type-service';
    import seedsPerSquaredMeterService from '../../../services/http/seeds-per-squared-meter-service';
    import typeService from '../../../services/http/type-service';
    import sizeService from '../../../services/http/size-service';
    import breedService from '../../../services/http/breed-service';
    import fractionService from '../../../services/http/fraction-service';
    import moment from 'moment'
    import { collect } from "collect.js";
    import { ref } from 'vue'
    import SeedsPerSquaredMeter from '@/models/seeds-per-squared-meter';

    const emit = defineEmits(['breadcrumbs']);

    const today = ref(moment());

    const weeklyTrendEdit = ref(false);
    const weeklyTrend = ref(0);
    const originalWeeklyTrend = ref(0);

    const cultivationCalendar = ref(collect());
    const originalCultivationCalendar = ref(collect());
    const cultivationCalendarEdit = ref(false);

    const seedsPerSquaredMeterEdit = ref(false);
    const seedsPerSquaredMeters = ref(collect());
    const originalSeedsPerSquaredMeters = ref(collect());

    const breedPerTypeEdit = ref(false);
    const breedPerTypes = ref(false);
    const originalBreedPerTypeFractions = ref(collect());
    const originalBreedPerTypeBreeds = ref(collect());

    const ready = ref(false)

    const types = ref(collect());
    const fractions = ref(collect());
    const breeds = ref(collect());
    const sizes = ref(collect());

    // ---------------------------------------------------------------------------------------------------------------------
    // Weekly trend
    // ---------------------------------------------------------------------------------------------------------------------
    const getWeeklyTrend = function() {
        settingService.first({filters: {
            key_is: 'weekly_trend'
        }}).then(setting => {
            setWeeklyTrend(setting ? setting.value : 0);
        });
    }

    const updateWeeklyTrend = function() {
        weeklyTrendEdit.value = false
        settingService.create({
            weekly_trend: weeklyTrend.value
        }).then(setting => {
            setWeeklyTrend(setting.value);
        });
    }

    const setWeeklyTrend = function(weeklyTrendValue) {
        weeklyTrend.value = parseInt(weeklyTrendValue);
        originalWeeklyTrend.value = parseInt(weeklyTrendValue);
    }

    // ---------------------------------------------------------------------------------------------------------------------
    // Cultivation calendar
    // ---------------------------------------------------------------------------------------------------------------------
    const resetCultivationCalendar = function() {
        cultivationCalendarEdit.value = ! cultivationCalendarEdit.value;

        for (let index in cultivationCalendar.value.all()) {
            cultivationCalendar.value.get(index).days = originalCultivationCalendar.value.get(index);
        }
    }

    const updateCultivationCalendar = function()
    {
        let data = cultivationCalendar.value.filter((day, index) => {
            return (
            day.days !== originalCultivationCalendar.value.get(index)
        )}).map((day) => ({
            month: day.month,
            day_of_month: day.dayOfMonth,
            days: day.days,
        }));

        cultivationCalendarDayService.create(data.all()).then(() => {
            for (let ccd of data) {
                let index = cultivationCalendar.value.search(cc => {
                    return (
                        cc.month === ccd.month
                        && cc.dayOfMonth === ccd.day_of_month
                    );
                });

                originalCultivationCalendar.value.splice(index, 1, [cultivationCalendar.value.get(index).days]);
            }

            cultivationCalendarEdit.value = false;

        });
    }

    const getCultivationCalendar = function()
    {
        cultivationCalendarDayService.get().then((data) => {
            cultivationCalendar.value = data;
            originalCultivationCalendar.value = data.pluck('days');
        });
    }


    // ---------------------------------------------------------------------------------------------------------------------
    // Seeds per m2
    // ---------------------------------------------------------------------------------------------------------------------
    const getSeedsPerSquaredMeter = function() {
        return seedsPerSquaredMeterService.get().then((seedsPerSquaredMeterModels) => {
            seedsPerSquaredMeters.value = seedsPerSquaredMeterModels;
        });
    }

    const resetSeedsPerSquaredMeter = function() {
        seedsPerSquaredMeterEdit.value = ! seedsPerSquaredMeterEdit.value;

        for (let index in seedsPerSquaredMeters.value.all()) {
            seedsPerSquaredMeters.value.get(index).seeds = originalSeedsPerSquaredMeters.value.get(index);
        }
    }

    const updateSeedsPerSquaredMeter = function() {
        let data = seedsPerSquaredMeters.value.filter((squaredMeter, index) => (
            squaredMeter.seeds !== originalSeedsPerSquaredMeters.value.get(index)
        )).map((squaredMeter) => ({
            week_number: squaredMeter.weekNumber,
            type_id: squaredMeter.typeId,
            seeds: squaredMeter.seeds,
        }));

        seedsPerSquaredMeterService.create(data.all()).then(() => {
            for (let ccd of data) {
                let index = seedsPerSquaredMeters.value.search(spsm => {
                    return (
                        spsm.weekNumber === ccd.week_number
                        && spsm.typeId === ccd.type_id
                    );
                });

                originalSeedsPerSquaredMeters.value.splice(index, 1, [seedsPerSquaredMeters.value.get(index).seeds]);
            }

            seedsPerSquaredMeterEdit.value = false;

        });
    }


    // ---------------------------------------------------------------------------------------------------------------------
    // Breed per type
    // ---------------------------------------------------------------------------------------------------------------------
    const getBreedPerTypes = function() {
        return breedPerTypeService.get().then((breedPerTypesModels) => {
            breedPerTypes.value = breedPerTypesModels;
            originalBreedPerTypeFractions.value = breedPerTypesModels.pluck('fractionId');
            originalBreedPerTypeBreeds.value = breedPerTypesModels.pluck('breedId');
        });
    }

    const breedPerType = function(weekNumber, typeId) {
        return breedPerTypes.value.where('weekNumber', weekNumber).firstWhere('typeId', typeId);
    }

    const resetBreedPerType = function() {
        breedPerTypeEdit.value = ! breedPerTypeEdit.value;
        for (let index in breedPerTypes.value.all()) {
            breedPerTypes.value.get(index).breedId = originalBreedPerTypeBreeds.value.get(index);
            breedPerTypes.value.get(index).fractionId = originalBreedPerTypeFractions.value.get(index);
        }
    }

    const updateBreedPerType = function() {
        let data = breedPerTypes.value.filter((breedPerType, index) => {
            return(
                breedPerType.breedId !== originalBreedPerTypeBreeds.value.get(index)
                || breedPerType.fractionId !== originalBreedPerTypeFractions.value.get(index)
            )}
        ).map((breedPerType) => ({
            week_number: breedPerType.weekNumber,
            type_id: breedPerType.typeId,
            fraction_id: breedPerType.fractionId,
            breed_id: breedPerType.breedId
        }));


        breedPerTypeService.create(data.all()).then(() => {
            originalBreedPerTypeFractions.value = breedPerTypes.value.pluck('fractionId');
            originalBreedPerTypeBreeds.value = breedPerTypes.value.pluck('breedId');
            breedPerTypeEdit.value = false;
        });
    }

    // ---------------------------------------------------------------------------------------------------------------------
    // Other
    // ---------------------------------------------------------------------------------------------------------------------
    const getTypes = function()
    {
        return typeService.get({filters: {name_is_not: 'micro'}}).then((typeModels) => {
            types.value = typeModels;
        });
    }

    const getBreeds = function()
    {
        return breedService.get({include: ['fractions']}).then((breedModels) => {
            breeds.value = breedModels;
        });
    }

    const getFractions = function()
    {
        return fractionService.get().then((fractionModels) => {
            fractions.value = fractionModels;
        });
    }

    getWeeklyTrend();
    getCultivationCalendar();

    const typesPromise = getTypes();

    Promise.all([
        getSeedsPerSquaredMeter(), typesPromise, getFractions(),
        getBreeds(), getBreedPerTypes(), typesPromise]
    ).then(() => {
        for (const type of types.value) {
            const filteredSeedsPerSquaredMeters = seedsPerSquaredMeters.value.where('typeId', type.id);
            for (let weeknr = 1; weeknr <= 53; weeknr++) {
                const spsm = filteredSeedsPerSquaredMeters.first(spsm => spsm.weekNumber === weeknr);
                if (! spsm) {
                    seedsPerSquaredMeters.value.push(new SeedsPerSquaredMeter({seeds: 0, typeId: type.id, weekNumber: weeknr}));
                }
            }
        }
        originalSeedsPerSquaredMeters.value = seedsPerSquaredMeters.value.pluck('seeds');

        ready.value = true
    })

    emit('breadcrumbs', [
        {label: 'Instellingen', to: '/settings'},
        {label: 'Zaaiplanning instellingen'}
    ])
</script>
