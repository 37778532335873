<template>
    <div id="prices">
        <div class="container">

            <!-- HEADER & FILTERS -->
            <div class="row">
                <div class="col">
                    <div class="page-header">
                        <h1>Prijzen wijzigen</h1>
                        <div class="styled-checkbox">
                            <label>
                                <input type="checkbox" v-model="showHidden">
                                <span>Verborgen regels tonen</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <input class="styled-input" type="text" placeholder="Zoeken" v-model="search">
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <button @click="confirmModal = true" class="button success">
                        Opslaan
                    </button>
                </div>
            </div>

            <!-- ARTICLES OVERVIEW -->
            <div class="prices" v-for="baseArticleGroup in baseArticleGroups.filter(bag => show(bag))">
                <div class="article-group">
                    <h2 class="article-group-header">{{ baseArticleGroup.name }}</h2>
                    <table class="styled-table">
                        <thead class="styled-table-header">
                            <tr>
                                <th>
                                    Naam
                                </th>
                                <th>
                                    Huidige prijs
                                </th>
                                <th>
                                    Nieuwe Prijs
                                </th>
                                <th>
                                    Laatste wijziging
                                </th>
                                <th>
                                    &nbsp;
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="styled-table-row article"
                                :class="{ hidden:baseArticle.hide }"
                                v-for="baseArticle of baseArticleGroup.baseArticles.filter(ba => show(baseArticleGroup, ba))"
                            >
                                <td style="text-align: left;">
                                    {{ baseArticle.name() }}
                                </td>
                                <td>
                                    {{ formatNumberAsMoney(originalBaseArticles.firstWhere('id', baseArticle.id).cents/100) }}
                                </td>
                                <td class="p-0">
                                    <label class="new-price-input">
                                        <input
                                            type="number" min="1" step="1"
                                            onkeydown="if(['.', ',','-','+','e','E'].includes(event.key)){event.preventDefault();}"
                                            v-model="baseArticle.cents"
                                            class="styled-input"
                                            @input="(e) => priceChanged(e, baseArticle.id)"
                                        >
                                        <div class="new-price-presenter" style="top: 22px">
                                            {{ formatNumberAsMoney(baseArticle.cents/100) }}
                                        </div>
                                    </label>
                                </td>
                                <td>
                                    {{ baseArticle.latestBaseArticlePrice.createdAt.format('DD-MM-YYYY HH:mm') }}
                                </td>
                                <td class="styled-table-row-icons" style="height:64px">
                                    <a @click="toggleBaseArticle(baseArticle)" v-tooltip="baseArticle.hide ? 'Weergeven' : 'Verbergen'">
                                        <Icon :name="baseArticle.hide ? 'eye-slash' : 'eye'" type="solid" />
                                    </a>
                                    <a v-tooltip="'Heeft invloed op'" @click="inspectArticleGroupModal = originalBaseArticles.firstWhere('id', baseArticle.id)" class="styled-link">
                                        <Icon name="list-bullet" type="solid" />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <button @click="confirmModal = true" class="button success">
                        Opslaan
                    </button>
                </div>
            </div>
        </div>
    </div>

    <Modal
        class="modal-lg"
        :excludeSaveButton="true"
        @close="inspectArticleGroupModal = null"
        v-if="inspectArticleGroupModal"
    >
        <template #header>Artikelen</template>

        <table class="styled-table">
            <thead class="styled-table-header">
                <th>
                    Naam
                </th>
                <th>
                    Oude prijs
                </th>
                <th>
                    Nieuwe prijs
                </th>
            </thead>
            <tbody class="styled-table-body">
                <tr v-for="article of inspectArticleGroupModal.articles">
                    <td>
                        {{ article.name }}
                    </td>
                    <td>
                        {{ formatNumberAsMoney(article.cents/100) }}
                    </td>
                    <td>
                        {{ formatNumberAsMoney(getPrice(article).totalCents / 100) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </Modal>

    <Modal class="modal-lg" v-if="confirmModal" @close="confirmModal = false" @save="save">
        <template #header>Bevestigen</template>

        <div>
            <b>Onderstaande prijswijzigingen worden direct doorgevoerd in Exact.</b>

            <table class="styled-table">
                <thead class="styled-table-header">
                    <th>
                        Naam
                    </th>
                    <th>
                        Oude prijs
                    </th>
                    <th>
                        Nieuwe prijs
                    </th>
                </thead>
                <tbody class="styled-table-body">
                    <tr v-for="article of changedArticles">
                        <td>
                            {{ article.name }}
                        </td>
                        <td>
                            {{ formatNumberAsMoney(article.cents/100) }}
                        </td>
                        <td>
                            {{ formatNumberAsMoney(getPrice(article).totalCents/100) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Modal>

    <LoaderWithOverlay v-if="loading"></LoaderWithOverlay>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import { useToast } from 'vue-toast-notification';
    import collect from 'collect.js';
    import UsePriceFunctions from './UsePriceFunctions';
    import UseBaseArticleFunctions from './UseBaseArticleFunctions';
    import baseArticleGroupService from '@/services/http/base-article-group-service';
    import baseArticleService from '@/services/http/base-article-service';
    import articleService from '@/services/http/article-service';
    import LoaderWithOverlay from '@/components/LoaderWithOverlay.vue';

    const emit = defineEmits(['breadcrumbs']);

    const { formatNumberAsMoney } = UsePriceFunctions()
    const { articlesWithBaseArticles, baseArticles, getModels, price } = UseBaseArticleFunctions()

    const search = ref('');

    const prices = ref(collect());
    const baseArticleGroups = ref(collect());
    const originalBaseArticles = ref(collect());

    const inspectArticleGroupModal = ref(null);

    const confirmModal = ref(false);
    const loading = ref(false);

    const showHidden = ref(false)

    const show = function(baseArticleGroup, baseArticle = null) {
        if (baseArticle && baseArticle.hide && ! showHidden.value) {
            return false;
        }
        return (

            baseArticleGroup.name.toLowerCase().includes(search.value.toLowerCase())
            || (baseArticle ? collect([baseArticle]) : baseArticleGroup.baseArticles).contains(
                ba => (! ba.hide && ba.name().toLowerCase().includes(search.value.toLowerCase()))
            )
        )
    }

    /*
    |--------------------------------------------------------------------------
    | Updates to server
    |--------------------------------------------------------------------------
    */
    const save = function() {
        loading.value = true;
        Promise.all([
            baseArticleService.updatePrices(changedPrices.value.all()),
            articleService.updatePrices(changedArticles.value.map(a => ({
                id: a.id,
                cents: getPrice(a).totalCents,
            })).all())
        ]).then(() => {
            getLocalModels().then(
                () => {
                    loading.value = false;
                    useToast().success('Prijzen opgeslagen');
                    confirmModal.value = false;
                }
            ).catch(() => {
                loading.value = false;
                useToast().error('Er is iets fout gegaan');
            })
        });
    }

    const toggleBaseArticle = function(baseArticle) {
        baseArticleService.edit(baseArticle.id, {hide: !baseArticle.hide}).then(() => {
            getLocalModels();
        });
    }

    /*
    |--------------------------------------------------------------------------
    | parseData
    |--------------------------------------------------------------------------
    */
    const priceChanged = function(e, id) {
        const value = e.currentTarget.value;
        const price = prices.value.firstWhere('id', id);
        price.cents = parseInt(value ? value : 0);
    }

    const changedArticles = computed(() => {
        return articlesWithBaseArticles.value.filter(a =>
            Math.round(a.cents) !== Math.round(getPrice(a).totalCents)
        );
    })

    const changedPrices = computed(() =>
        prices.value.filter(price => originalBaseArticles.value.firstWhere('id', price.id).cents !== price.cents)
    )

    const parseData = function() {
        for (const originalBaseArticle of originalBaseArticles.value) {
            originalBaseArticle.articles = articlesWithBaseArticles.value.filter(
                article => article.baseArticles.contains('id', originalBaseArticle.id)
            );

        }
    }

    const getPrice = function(article) {
        return price(article, prices.value.all());
    }

    /*
    |--------------------------------------------------------------------------
    | Getters
    |--------------------------------------------------------------------------
    */
    const getLocalModels = function() {
        return Promise.all([getModels(), getBaseArticleGroups()]).then(([a, baseArticleGroupModels]) => {
            for(const baseArticleGroup of baseArticleGroupModels) {
                baseArticleGroup.baseArticles = baseArticles.value.where('baseArticleGroupId', baseArticleGroup.id);
            }

            baseArticleGroups.value = baseArticleGroupModels;

            originalBaseArticles.value = collect(
                JSON.parse(JSON.stringify(baseArticleGroups.value.pluck('baseArticles').flatten(1).all()))
            );

            prices.value = collect(
                JSON.parse(JSON.stringify(baseArticleGroups.value.pluck('baseArticles').flatten(1).all()))
            ).map(baseArticle => ({id: baseArticle.id, cents: baseArticle.cents}));

            parseData();
        })
    }

    const getBaseArticleGroups = function() {
        return baseArticleGroupService.get();
    }

    getLocalModels();


    emit('breadcrumbs', [
        {label: 'Prijzen', to: '/prices'},
        {label: 'Prijzen wijzigen'},
    ]);

</script>
